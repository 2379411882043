/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FlightLocation } from './FlightLocation';
import {
    FlightLocationFromJSON,
    FlightLocationFromJSONTyped,
    FlightLocationToJSON,
} from './FlightLocation';

/**
 * 
 * @export
 * @interface AirportHistory
 */
export interface AirportHistory {
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    iataCode?: string;
    /**
     * 
     * @type {number}
     * @memberof AirportHistory
     */
    departureCount?: number;
    /**
     * 
     * @type {number}
     * @memberof AirportHistory
     */
    arrivalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof AirportHistory
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof AirportHistory
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    icaoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    iataCountryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    iataCityCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    countryName?: string;
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    countryPlaceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    continentName?: string;
    /**
     * 
     * @type {string}
     * @memberof AirportHistory
     */
    grouping?: AirportHistoryGroupingEnum;
    /**
     * 
     * @type {Array<FlightLocation>}
     * @memberof AirportHistory
     */
    departureHistory?: Array<FlightLocation>;
    /**
     * 
     * @type {Array<FlightLocation>}
     * @memberof AirportHistory
     */
    arrivalHistory?: Array<FlightLocation>;
}


/**
 * @export
 */
export const AirportHistoryGroupingEnum = {
    Past: 'past',
    Future: 'future'
} as const;
export type AirportHistoryGroupingEnum = typeof AirportHistoryGroupingEnum[keyof typeof AirportHistoryGroupingEnum];


/**
 * Check if a given object implements the AirportHistory interface.
 */
export function instanceOfAirportHistory(value: object): value is AirportHistory {
    return true;
}

export function AirportHistoryFromJSON(json: any): AirportHistory {
    return AirportHistoryFromJSONTyped(json, false);
}

export function AirportHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AirportHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'iataCode': json['iata_code'] == null ? undefined : json['iata_code'],
        'departureCount': json['departure_count'] == null ? undefined : json['departure_count'],
        'arrivalCount': json['arrival_count'] == null ? undefined : json['arrival_count'],
        'latitude': json['latitude'] == null ? undefined : json['latitude'],
        'longitude': json['longitude'] == null ? undefined : json['longitude'],
        'icaoCode': json['icao_code'] == null ? undefined : json['icao_code'],
        'iataCountryCode': json['iata_country_code'] == null ? undefined : json['iata_country_code'],
        'iataCityCode': json['iata_city_code'] == null ? undefined : json['iata_city_code'],
        'cityName': json['city_name'] == null ? undefined : json['city_name'],
        'countryName': json['country_name'] == null ? undefined : json['country_name'],
        'countryPlaceId': json['country_place_id'] == null ? undefined : json['country_place_id'],
        'continentName': json['continent_name'] == null ? undefined : json['continent_name'],
        'grouping': json['grouping'] == null ? undefined : json['grouping'],
        'departureHistory': json['departure_history'] == null ? undefined : ((json['departure_history'] as Array<any>).map(FlightLocationFromJSON)),
        'arrivalHistory': json['arrival_history'] == null ? undefined : ((json['arrival_history'] as Array<any>).map(FlightLocationFromJSON)),
    };
}

export function AirportHistoryToJSON(value?: AirportHistory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'iata_code': value['iataCode'],
        'departure_count': value['departureCount'],
        'arrival_count': value['arrivalCount'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'icao_code': value['icaoCode'],
        'iata_country_code': value['iataCountryCode'],
        'iata_city_code': value['iataCityCode'],
        'city_name': value['cityName'],
        'country_name': value['countryName'],
        'country_place_id': value['countryPlaceId'],
        'continent_name': value['continentName'],
        'grouping': value['grouping'],
        'departure_history': value['departureHistory'] == null ? undefined : ((value['departureHistory'] as Array<any>).map(FlightLocationToJSON)),
        'arrival_history': value['arrivalHistory'] == null ? undefined : ((value['arrivalHistory'] as Array<any>).map(FlightLocationToJSON)),
    };
}

