import React, { SVGAttributes } from "react";

type Props = SVGAttributes<SVGSVGElement> & {
  color?: string;
};

const PointsIcon: React.FC<Props> = ({ color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={18}
      viewBox="0 0 17 18"
      fill="none"
      {...props}
    >
      <circle cx={5.6} cy={12.4} r={5.6} fill={color ?? "#fff"} />
      <path
        fill={color ?? "#fff"}
        d="M13.381 10.461a5.6 5.6 0 1 0-8.363-5.323 5.6 5.6 0 0 1 8.363 5.323Z"
      />
    </svg>
  );
};

export default PointsIcon;
