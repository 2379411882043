/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserCreditsDetail } from './UserCreditsDetail';
import {
    UserCreditsDetailFromJSON,
    UserCreditsDetailFromJSONTyped,
    UserCreditsDetailToJSON,
} from './UserCreditsDetail';

/**
 * 
 * @export
 * @interface UserCredits
 */
export interface UserCredits {
    /**
     * This user's referral code which can be used to make referrals.
     * @type {string}
     * @memberof UserCredits
     */
    referralCode: string;
    /**
     * 
     * @type {UserCreditsDetail}
     * @memberof UserCredits
     */
    userCreditsDetail: UserCreditsDetail;
}

/**
 * Check if a given object implements the UserCredits interface.
 */
export function instanceOfUserCredits(value: object): value is UserCredits {
    if (!('referralCode' in value) || value['referralCode'] === undefined) return false;
    if (!('userCreditsDetail' in value) || value['userCreditsDetail'] === undefined) return false;
    return true;
}

export function UserCreditsFromJSON(json: any): UserCredits {
    return UserCreditsFromJSONTyped(json, false);
}

export function UserCreditsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCredits {
    if (json == null) {
        return json;
    }
    return {
        
        'referralCode': json['referral_code'],
        'userCreditsDetail': UserCreditsDetailFromJSON(json['user_credits_detail']),
    };
}

export function UserCreditsToJSON(value?: UserCredits | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'referral_code': value['referralCode'],
        'user_credits_detail': UserCreditsDetailToJSON(value['userCreditsDetail']),
    };
}

