/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscriptionRequest
 */
export interface SubscriptionRequest {
    /**
     * Set to true to subscribe the user to Gondola emails, false to unsubscribe.
     * @type {boolean}
     * @memberof SubscriptionRequest
     */
    subscribed: boolean;
    /**
     * The email address to subscribe to Gondola emails.
     * @type {string}
     * @memberof SubscriptionRequest
     */
    emailAddress?: string;
}

/**
 * Check if a given object implements the SubscriptionRequest interface.
 */
export function instanceOfSubscriptionRequest(value: object): value is SubscriptionRequest {
    if (!('subscribed' in value) || value['subscribed'] === undefined) return false;
    return true;
}

export function SubscriptionRequestFromJSON(json: any): SubscriptionRequest {
    return SubscriptionRequestFromJSONTyped(json, false);
}

export function SubscriptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'subscribed': json['subscribed'],
        'emailAddress': json['email_address'] == null ? undefined : json['email_address'],
    };
}

export function SubscriptionRequestToJSON(value?: SubscriptionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'subscribed': value['subscribed'],
        'email_address': value['emailAddress'],
    };
}

