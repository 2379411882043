/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    code: string;
    /**
     * The effective start datetime of this promotion.
     * @type {Date}
     * @memberof Promotion
     */
    effectiveStartAt: Date;
    /**
     * The effective end datetime of this promotion, or null if there's no effective end date.
     * @type {Date}
     * @memberof Promotion
     */
    effectiveEndAt?: Date;
}

/**
 * Check if a given object implements the Promotion interface.
 */
export function instanceOfPromotion(value: object): value is Promotion {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('effectiveStartAt' in value) || value['effectiveStartAt'] === undefined) return false;
    return true;
}

export function PromotionFromJSON(json: any): Promotion {
    return PromotionFromJSONTyped(json, false);
}

export function PromotionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Promotion {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'effectiveStartAt': (new Date(json['effective_start_at'])),
        'effectiveEndAt': json['effective_end_at'] == null ? undefined : (new Date(json['effective_end_at'])),
    };
}

export function PromotionToJSON(value?: Promotion | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'effective_start_at': ((value['effectiveStartAt']).toISOString()),
        'effective_end_at': value['effectiveEndAt'] == null ? undefined : ((value['effectiveEndAt']).toISOString()),
    };
}

