/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoyaltyRedemptionValue
 */
export interface LoyaltyRedemptionValue {
    /**
     * 
     * @type {number}
     * @memberof LoyaltyRedemptionValue
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRedemptionValue
     */
    programName?: string;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyRedemptionValue
     */
    averagePointRedemptionValue?: number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyRedemptionValue
     */
    shortEconomy?: number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyRedemptionValue
     */
    shortBusiness?: number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyRedemptionValue
     */
    longEconomy?: number;
    /**
     * 
     * @type {number}
     * @memberof LoyaltyRedemptionValue
     */
    longBusiness?: number;
}

/**
 * Check if a given object implements the LoyaltyRedemptionValue interface.
 */
export function instanceOfLoyaltyRedemptionValue(value: object): value is LoyaltyRedemptionValue {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function LoyaltyRedemptionValueFromJSON(json: any): LoyaltyRedemptionValue {
    return LoyaltyRedemptionValueFromJSONTyped(json, false);
}

export function LoyaltyRedemptionValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyRedemptionValue {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'programName': json['program_name'] == null ? undefined : json['program_name'],
        'averagePointRedemptionValue': json['average_point_redemption_value'] == null ? undefined : json['average_point_redemption_value'],
        'shortEconomy': json['short_economy'] == null ? undefined : json['short_economy'],
        'shortBusiness': json['short_business'] == null ? undefined : json['short_business'],
        'longEconomy': json['long_economy'] == null ? undefined : json['long_economy'],
        'longBusiness': json['long_business'] == null ? undefined : json['long_business'],
    };
}

export function LoyaltyRedemptionValueToJSON(value?: LoyaltyRedemptionValue | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'program_name': value['programName'],
        'average_point_redemption_value': value['averagePointRedemptionValue'],
        'short_economy': value['shortEconomy'],
        'short_business': value['shortBusiness'],
        'long_economy': value['longEconomy'],
        'long_business': value['longBusiness'],
    };
}

