/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MxReward } from './MxReward';
import {
    MxRewardFromJSON,
    MxRewardFromJSONTyped,
    MxRewardToJSON,
} from './MxReward';

/**
 * 
 * @export
 * @interface MxRewards
 */
export interface MxRewards {
    /**
     * the user's total loyalty balance
     * @type {number}
     * @memberof MxRewards
     */
    totalRewardPoints?: number;
    /**
     * the cash value of the loyalty points
     * @type {number}
     * @memberof MxRewards
     */
    cashValue?: number;
    /**
     * 
     * @type {Array<MxReward>}
     * @memberof MxRewards
     */
    items?: Array<MxReward>;
}

/**
 * Check if a given object implements the MxRewards interface.
 */
export function instanceOfMxRewards(value: object): value is MxRewards {
    return true;
}

export function MxRewardsFromJSON(json: any): MxRewards {
    return MxRewardsFromJSONTyped(json, false);
}

export function MxRewardsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MxRewards {
    if (json == null) {
        return json;
    }
    return {
        
        'totalRewardPoints': json['total_reward_points'] == null ? undefined : json['total_reward_points'],
        'cashValue': json['cash_value'] == null ? undefined : json['cash_value'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(MxRewardFromJSON)),
    };
}

export function MxRewardsToJSON(value?: MxRewards | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total_reward_points': value['totalRewardPoints'],
        'cash_value': value['cashValue'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(MxRewardToJSON)),
    };
}

