/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HotelEarnings
 */
export interface HotelEarnings {
    /**
     * Number of status nights accrued
     * @type {number}
     * @memberof HotelEarnings
     */
    statusNights?: number;
    /**
     * Number of points accrued
     * @type {number}
     * @memberof HotelEarnings
     */
    points?: number;
}

/**
 * Check if a given object implements the HotelEarnings interface.
 */
export function instanceOfHotelEarnings(value: object): value is HotelEarnings {
    return true;
}

export function HotelEarningsFromJSON(json: any): HotelEarnings {
    return HotelEarningsFromJSONTyped(json, false);
}

export function HotelEarningsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelEarnings {
    if (json == null) {
        return json;
    }
    return {
        
        'statusNights': json['status_nights'] == null ? undefined : json['status_nights'],
        'points': json['points'] == null ? undefined : json['points'],
    };
}

export function HotelEarningsToJSON(value?: HotelEarnings | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status_nights': value['statusNights'],
        'points': value['points'],
    };
}

