/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoyaltyCallRequest } from './LoyaltyCallRequest';
import {
    LoyaltyCallRequestFromJSON,
    LoyaltyCallRequestFromJSONTyped,
    LoyaltyCallRequestToJSON,
} from './LoyaltyCallRequest';

/**
 * 
 * @export
 * @interface CallTranscriptRequest
 */
export interface CallTranscriptRequest {
    /**
     * 
     * @type {string}
     * @memberof CallTranscriptRequest
     */
    transcript: string;
    /**
     * 
     * @type {LoyaltyCallRequest}
     * @memberof CallTranscriptRequest
     */
    originalRequest: LoyaltyCallRequest;
}

/**
 * Check if a given object implements the CallTranscriptRequest interface.
 */
export function instanceOfCallTranscriptRequest(value: object): value is CallTranscriptRequest {
    if (!('transcript' in value) || value['transcript'] === undefined) return false;
    if (!('originalRequest' in value) || value['originalRequest'] === undefined) return false;
    return true;
}

export function CallTranscriptRequestFromJSON(json: any): CallTranscriptRequest {
    return CallTranscriptRequestFromJSONTyped(json, false);
}

export function CallTranscriptRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CallTranscriptRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'transcript': json['transcript'],
        'originalRequest': LoyaltyCallRequestFromJSON(json['original_request']),
    };
}

export function CallTranscriptRequestToJSON(value?: CallTranscriptRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'transcript': value['transcript'],
        'original_request': LoyaltyCallRequestToJSON(value['originalRequest']),
    };
}

