/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RatePastStayWelcomeActions } from './RatePastStayWelcomeActions';
import {
    RatePastStayWelcomeActionsFromJSON,
    RatePastStayWelcomeActionsFromJSONTyped,
    RatePastStayWelcomeActionsToJSON,
} from './RatePastStayWelcomeActions';
import type { EmailSyncTokenExpiredWelcomeAction } from './EmailSyncTokenExpiredWelcomeAction';
import {
    EmailSyncTokenExpiredWelcomeActionFromJSON,
    EmailSyncTokenExpiredWelcomeActionFromJSONTyped,
    EmailSyncTokenExpiredWelcomeActionToJSON,
} from './EmailSyncTokenExpiredWelcomeAction';

/**
 * 
 * @export
 * @interface WelcomeActions
 */
export interface WelcomeActions {
    /**
     * 
     * @type {EmailSyncTokenExpiredWelcomeAction}
     * @memberof WelcomeActions
     */
    emailSyncTokenExpiredWelcomeAction?: EmailSyncTokenExpiredWelcomeAction;
    /**
     * 
     * @type {RatePastStayWelcomeActions}
     * @memberof WelcomeActions
     */
    ratePastStayWelcomeActions: RatePastStayWelcomeActions;
}

/**
 * Check if a given object implements the WelcomeActions interface.
 */
export function instanceOfWelcomeActions(value: object): value is WelcomeActions {
    if (!('ratePastStayWelcomeActions' in value) || value['ratePastStayWelcomeActions'] === undefined) return false;
    return true;
}

export function WelcomeActionsFromJSON(json: any): WelcomeActions {
    return WelcomeActionsFromJSONTyped(json, false);
}

export function WelcomeActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WelcomeActions {
    if (json == null) {
        return json;
    }
    return {
        
        'emailSyncTokenExpiredWelcomeAction': json['email_sync_token_expired_welcome_action'] == null ? undefined : EmailSyncTokenExpiredWelcomeActionFromJSON(json['email_sync_token_expired_welcome_action']),
        'ratePastStayWelcomeActions': RatePastStayWelcomeActionsFromJSON(json['rate_past_stay_welcome_actions']),
    };
}

export function WelcomeActionsToJSON(value?: WelcomeActions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email_sync_token_expired_welcome_action': EmailSyncTokenExpiredWelcomeActionToJSON(value['emailSyncTokenExpiredWelcomeAction']),
        'rate_past_stay_welcome_actions': RatePastStayWelcomeActionsToJSON(value['ratePastStayWelcomeActions']),
    };
}

