/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Account } from './Account';
import {
    AccountFromJSON,
    AccountFromJSONTyped,
    AccountToJSON,
} from './Account';

/**
 * 
 * @export
 * @interface UserLinkedAccounts
 */
export interface UserLinkedAccounts {
    /**
     * 
     * @type {Array<Account>}
     * @memberof UserLinkedAccounts
     */
    items?: Array<Account>;
}

/**
 * Check if a given object implements the UserLinkedAccounts interface.
 */
export function instanceOfUserLinkedAccounts(value: object): value is UserLinkedAccounts {
    return true;
}

export function UserLinkedAccountsFromJSON(json: any): UserLinkedAccounts {
    return UserLinkedAccountsFromJSONTyped(json, false);
}

export function UserLinkedAccountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserLinkedAccounts {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(AccountFromJSON)),
    };
}

export function UserLinkedAccountsToJSON(value?: UserLinkedAccounts | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(AccountToJSON)),
    };
}

