/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyncStatus
 */
export interface SyncStatus {
    /**
     * The number of emails parsed so far to build the itineraries.
     * @type {number}
     * @memberof SyncStatus
     */
    emailsParsed?: number;
    /**
     * The total number of emails to be parsed.  None indicates that a parse is not in progres
     * @type {number}
     * @memberof SyncStatus
     */
    totalEmails?: number;
}

/**
 * Check if a given object implements the SyncStatus interface.
 */
export function instanceOfSyncStatus(value: object): value is SyncStatus {
    return true;
}

export function SyncStatusFromJSON(json: any): SyncStatus {
    return SyncStatusFromJSONTyped(json, false);
}

export function SyncStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'emailsParsed': json['emails_parsed'] == null ? undefined : json['emails_parsed'],
        'totalEmails': json['total_emails'] == null ? undefined : json['total_emails'],
    };
}

export function SyncStatusToJSON(value?: SyncStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'emails_parsed': value['emailsParsed'],
        'total_emails': value['totalEmails'],
    };
}

