/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelSummary } from './HotelSummary';
import {
    HotelSummaryFromJSON,
    HotelSummaryFromJSONTyped,
    HotelSummaryToJSON,
} from './HotelSummary';
import type { Hotel } from './Hotel';
import {
    HotelFromJSON,
    HotelFromJSONTyped,
    HotelToJSON,
} from './Hotel';

/**
 * 
 * @export
 * @interface HotelHistory
 */
export interface HotelHistory {
    /**
     * 
     * @type {HotelSummary}
     * @memberof HotelHistory
     */
    summary?: HotelSummary;
    /**
     * 
     * @type {Array<Hotel>}
     * @memberof HotelHistory
     */
    history?: Array<Hotel>;
    /**
     * 
     * @type {string}
     * @memberof HotelHistory
     */
    countryName?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelHistory
     */
    countryPlaceId?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelHistory
     */
    continentName?: string;
    /**
     * 
     * @type {number}
     * @memberof HotelHistory
     */
    visitCount?: number;
    /**
     * 
     * @type {string}
     * @memberof HotelHistory
     */
    grouping?: HotelHistoryGroupingEnum;
}


/**
 * @export
 */
export const HotelHistoryGroupingEnum = {
    Past: 'past',
    Future: 'future'
} as const;
export type HotelHistoryGroupingEnum = typeof HotelHistoryGroupingEnum[keyof typeof HotelHistoryGroupingEnum];


/**
 * Check if a given object implements the HotelHistory interface.
 */
export function instanceOfHotelHistory(value: object): value is HotelHistory {
    return true;
}

export function HotelHistoryFromJSON(json: any): HotelHistory {
    return HotelHistoryFromJSONTyped(json, false);
}

export function HotelHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'summary': json['summary'] == null ? undefined : HotelSummaryFromJSON(json['summary']),
        'history': json['history'] == null ? undefined : ((json['history'] as Array<any>).map(HotelFromJSON)),
        'countryName': json['country_name'] == null ? undefined : json['country_name'],
        'countryPlaceId': json['country_place_id'] == null ? undefined : json['country_place_id'],
        'continentName': json['continent_name'] == null ? undefined : json['continent_name'],
        'visitCount': json['visit_count'] == null ? undefined : json['visit_count'],
        'grouping': json['grouping'] == null ? undefined : json['grouping'],
    };
}

export function HotelHistoryToJSON(value?: HotelHistory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'summary': HotelSummaryToJSON(value['summary']),
        'history': value['history'] == null ? undefined : ((value['history'] as Array<any>).map(HotelToJSON)),
        'country_name': value['countryName'],
        'country_place_id': value['countryPlaceId'],
        'continent_name': value['continentName'],
        'visit_count': value['visitCount'],
        'grouping': value['grouping'],
    };
}

