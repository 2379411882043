/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoyaltyRedemptionValue } from './LoyaltyRedemptionValue';
import {
    LoyaltyRedemptionValueFromJSON,
    LoyaltyRedemptionValueFromJSONTyped,
    LoyaltyRedemptionValueToJSON,
} from './LoyaltyRedemptionValue';

/**
 * Loyalty Redemption Value Response
 * @export
 * @interface LoyaltyRedemptionValueResponse
 */
export interface LoyaltyRedemptionValueResponse {
    /**
     * 
     * @type {Array<LoyaltyRedemptionValue>}
     * @memberof LoyaltyRedemptionValueResponse
     */
    loyaltyRedemptionValues?: Array<LoyaltyRedemptionValue>;
}

/**
 * Check if a given object implements the LoyaltyRedemptionValueResponse interface.
 */
export function instanceOfLoyaltyRedemptionValueResponse(value: object): value is LoyaltyRedemptionValueResponse {
    return true;
}

export function LoyaltyRedemptionValueResponseFromJSON(json: any): LoyaltyRedemptionValueResponse {
    return LoyaltyRedemptionValueResponseFromJSONTyped(json, false);
}

export function LoyaltyRedemptionValueResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyRedemptionValueResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'loyaltyRedemptionValues': json['loyalty_redemption_values'] == null ? undefined : ((json['loyalty_redemption_values'] as Array<any>).map(LoyaltyRedemptionValueFromJSON)),
    };
}

export function LoyaltyRedemptionValueResponseToJSON(value?: LoyaltyRedemptionValueResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'loyalty_redemption_values': value['loyaltyRedemptionValues'] == null ? undefined : ((value['loyaltyRedemptionValues'] as Array<any>).map(LoyaltyRedemptionValueToJSON)),
    };
}

