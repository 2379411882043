/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoyaltyPerks } from './LoyaltyPerks';
import {
    LoyaltyPerksFromJSON,
    LoyaltyPerksFromJSONTyped,
    LoyaltyPerksToJSON,
} from './LoyaltyPerks';
import type { LoyaltyRedemptionValue } from './LoyaltyRedemptionValue';
import {
    LoyaltyRedemptionValueFromJSON,
    LoyaltyRedemptionValueFromJSONTyped,
    LoyaltyRedemptionValueToJSON,
} from './LoyaltyRedemptionValue';

/**
 * 
 * @export
 * @interface MxReward
 */
export interface MxReward {
    /**
     * 
     * @type {number}
     * @memberof MxReward
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MxReward
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof MxReward
     */
    institutionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MxReward
     */
    institutionName?: string;
    /**
     * 
     * @type {string}
     * @memberof MxReward
     */
    userGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MxReward
     */
    memberGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof MxReward
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MxReward
     */
    creditCardProductName?: string;
    /**
     * 
     * @type {string}
     * @memberof MxReward
     */
    connectionStatus?: string;
    /**
     * 
     * @type {Date}
     * @memberof MxReward
     */
    staleSince?: Date;
    /**
     * 
     * @type {string}
     * @memberof MxReward
     */
    staleReason?: string;
    /**
     * 
     * @type {number}
     * @memberof MxReward
     */
    balance?: number;
    /**
     * the cash value of the loyalty points
     * @type {number}
     * @memberof MxReward
     */
    cashValue?: number;
    /**
     * 
     * @type {LoyaltyPerks}
     * @memberof MxReward
     */
    loyaltyPerks?: LoyaltyPerks;
    /**
     * 
     * @type {LoyaltyRedemptionValue}
     * @memberof MxReward
     */
    loyaltyRedemptionValue?: LoyaltyRedemptionValue;
}

/**
 * Check if a given object implements the MxReward interface.
 */
export function instanceOfMxReward(value: object): value is MxReward {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    return true;
}

export function MxRewardFromJSON(json: any): MxReward {
    return MxRewardFromJSONTyped(json, false);
}

export function MxRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): MxReward {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['user_id'],
        'institutionCode': json['institution_code'] == null ? undefined : json['institution_code'],
        'institutionName': json['institution_name'] == null ? undefined : json['institution_name'],
        'userGuid': json['user_guid'] == null ? undefined : json['user_guid'],
        'memberGuid': json['member_guid'] == null ? undefined : json['member_guid'],
        'accountNumber': json['account_number'] == null ? undefined : json['account_number'],
        'creditCardProductName': json['credit_card_product_name'] == null ? undefined : json['credit_card_product_name'],
        'connectionStatus': json['connection_status'] == null ? undefined : json['connection_status'],
        'staleSince': json['stale_since'] == null ? undefined : (new Date(json['stale_since'])),
        'staleReason': json['stale_reason'] == null ? undefined : json['stale_reason'],
        'balance': json['balance'] == null ? undefined : json['balance'],
        'cashValue': json['cash_value'] == null ? undefined : json['cash_value'],
        'loyaltyPerks': json['loyalty_perks'] == null ? undefined : LoyaltyPerksFromJSON(json['loyalty_perks']),
        'loyaltyRedemptionValue': json['loyalty_redemption_value'] == null ? undefined : LoyaltyRedemptionValueFromJSON(json['loyalty_redemption_value']),
    };
}

export function MxRewardToJSON(value?: MxReward | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user_id': value['userId'],
        'institution_code': value['institutionCode'],
        'institution_name': value['institutionName'],
        'user_guid': value['userGuid'],
        'member_guid': value['memberGuid'],
        'account_number': value['accountNumber'],
        'credit_card_product_name': value['creditCardProductName'],
        'connection_status': value['connectionStatus'],
        'stale_since': value['staleSince'] == null ? undefined : ((value['staleSince']).toISOString()),
        'stale_reason': value['staleReason'],
        'balance': value['balance'],
        'cash_value': value['cashValue'],
        'loyalty_perks': LoyaltyPerksToJSON(value['loyaltyPerks']),
        'loyalty_redemption_value': LoyaltyRedemptionValueToJSON(value['loyaltyRedemptionValue']),
    };
}

