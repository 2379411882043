/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrencyAmount } from './CurrencyAmount';
import {
    CurrencyAmountFromJSON,
    CurrencyAmountFromJSONTyped,
    CurrencyAmountToJSON,
} from './CurrencyAmount';

/**
 * 
 * @export
 * @interface AdjustCreditRequest
 */
export interface AdjustCreditRequest {
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof AdjustCreditRequest
     */
    amount: CurrencyAmount;
    /**
     * 
     * @type {string}
     * @memberof AdjustCreditRequest
     */
    transactionType: AdjustCreditRequestTransactionTypeEnum;
}


/**
 * @export
 */
export const AdjustCreditRequestTransactionTypeEnum = {
    BookingDebit: 'booking_debit'
} as const;
export type AdjustCreditRequestTransactionTypeEnum = typeof AdjustCreditRequestTransactionTypeEnum[keyof typeof AdjustCreditRequestTransactionTypeEnum];


/**
 * Check if a given object implements the AdjustCreditRequest interface.
 */
export function instanceOfAdjustCreditRequest(value: object): value is AdjustCreditRequest {
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('transactionType' in value) || value['transactionType'] === undefined) return false;
    return true;
}

export function AdjustCreditRequestFromJSON(json: any): AdjustCreditRequest {
    return AdjustCreditRequestFromJSONTyped(json, false);
}

export function AdjustCreditRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdjustCreditRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': CurrencyAmountFromJSON(json['amount']),
        'transactionType': json['transaction_type'],
    };
}

export function AdjustCreditRequestToJSON(value?: AdjustCreditRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': CurrencyAmountToJSON(value['amount']),
        'transaction_type': value['transactionType'],
    };
}

