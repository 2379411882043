/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TripHistoryRequest
 */
export interface TripHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof TripHistoryRequest
     */
    userId: string;
}

/**
 * Check if a given object implements the TripHistoryRequest interface.
 */
export function instanceOfTripHistoryRequest(value: object): value is TripHistoryRequest {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    return true;
}

export function TripHistoryRequestFromJSON(json: any): TripHistoryRequest {
    return TripHistoryRequestFromJSONTyped(json, false);
}

export function TripHistoryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripHistoryRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
    };
}

export function TripHistoryRequestToJSON(value?: TripHistoryRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_id': value['userId'],
    };
}

