import React, { SVGAttributes } from "react";

type Props = SVGAttributes<SVGSVGElement> & {
  color?: string;
};

const MapUnfold: React.FC<Props> = ({ color, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? "#fff"}
          d="M17.884 3.882a.626.626 0 0 0-.536-.113l-4.775 1.194L7.78 2.566a.625.625 0 0 0-.432-.047l-5 1.25a.625.625 0 0 0-.473.606v11.25a.624.624 0 0 0 .777.606l4.775-1.194 4.793 2.397a.637.637 0 0 0 .432.047l5-1.25a.625.625 0 0 0 .473-.606V4.375a.625.625 0 0 0-.24-.493ZM7.5 13.75a.624.624 0 0 0-.152.019l-4.223 1.055v-9.96l4.302-1.076.073.035v9.927Zm9.375 1.387-4.302 1.075-.073-.035V6.25a.625.625 0 0 0 .152-.018l4.223-1.056v9.96Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={color ?? "#fff"} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MapUnfold;
