/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FlightEarnings } from './FlightEarnings';
import {
    FlightEarningsFromJSON,
    FlightEarningsFromJSONTyped,
    FlightEarningsToJSON,
} from './FlightEarnings';

/**
 * 
 * @export
 * @interface FlightBookingDetails
 */
export interface FlightBookingDetails {
    /**
     * Gondola-internal booking ID. For user-facing Gondola confirmation number, use gondola_booking_id instead.
     * @type {number}
     * @memberof FlightBookingDetails
     */
    bookingId?: number;
    /**
     * User-facing Gondola booking confirmation number.
     * @type {string}
     * @memberof FlightBookingDetails
     */
    gondolaBookingId?: string;
    /**
     * 
     * @type {FlightEarnings}
     * @memberof FlightBookingDetails
     */
    flightEarnings?: FlightEarnings;
    /**
     * the user's Loyalty ID associated with the booking'
     * @type {string}
     * @memberof FlightBookingDetails
     */
    loyaltyId?: string;
}

/**
 * Check if a given object implements the FlightBookingDetails interface.
 */
export function instanceOfFlightBookingDetails(value: object): value is FlightBookingDetails {
    return true;
}

export function FlightBookingDetailsFromJSON(json: any): FlightBookingDetails {
    return FlightBookingDetailsFromJSONTyped(json, false);
}

export function FlightBookingDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlightBookingDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'bookingId': json['booking_id'] == null ? undefined : json['booking_id'],
        'gondolaBookingId': json['gondola_booking_id'] == null ? undefined : json['gondola_booking_id'],
        'flightEarnings': json['flight_earnings'] == null ? undefined : FlightEarningsFromJSON(json['flight_earnings']),
        'loyaltyId': json['loyalty_id'] == null ? undefined : json['loyalty_id'],
    };
}

export function FlightBookingDetailsToJSON(value?: FlightBookingDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'booking_id': value['bookingId'],
        'gondola_booking_id': value['gondolaBookingId'],
        'flight_earnings': FlightEarningsToJSON(value['flightEarnings']),
        'loyalty_id': value['loyaltyId'],
    };
}

