/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserAttributes } from './UserAttributes';
import {
    UserAttributesFromJSON,
    UserAttributesFromJSONTyped,
    UserAttributesToJSON,
} from './UserAttributes';

/**
 * 
 * @export
 * @interface UserPostRequest
 */
export interface UserPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPostRequest
     */
    email: string;
    /**
     * 
     * @type {UserAttributes}
     * @memberof UserPostRequest
     */
    attributes?: UserAttributes;
}

/**
 * Check if a given object implements the UserPostRequest interface.
 */
export function instanceOfUserPostRequest(value: object): value is UserPostRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function UserPostRequestFromJSON(json: any): UserPostRequest {
    return UserPostRequestFromJSONTyped(json, false);
}

export function UserPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'attributes': json['attributes'] == null ? undefined : UserAttributesFromJSON(json['attributes']),
    };
}

export function UserPostRequestToJSON(value?: UserPostRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'attributes': UserAttributesToJSON(value['attributes']),
    };
}

