/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TripHotels } from './TripHotels';
import {
    TripHotelsFromJSON,
    TripHotelsFromJSONTyped,
    TripHotelsToJSON,
} from './TripHotels';
import type { TripFlights } from './TripFlights';
import {
    TripFlightsFromJSON,
    TripFlightsFromJSONTyped,
    TripFlightsToJSON,
} from './TripFlights';

/**
 * 
 * @export
 * @interface Trip
 */
export interface Trip {
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    grouping?: TripGroupingEnum;
    /**
     * 
     * @type {Date}
     * @memberof Trip
     */
    sortDatetime?: Date;
    /**
     * 
     * @type {TripHotels}
     * @memberof Trip
     */
    hotels?: TripHotels;
    /**
     * 
     * @type {TripFlights}
     * @memberof Trip
     */
    flights?: TripFlights;
}


/**
 * @export
 */
export const TripGroupingEnum = {
    Past: 'past',
    Future: 'future'
} as const;
export type TripGroupingEnum = typeof TripGroupingEnum[keyof typeof TripGroupingEnum];


/**
 * Check if a given object implements the Trip interface.
 */
export function instanceOfTrip(value: object): value is Trip {
    return true;
}

export function TripFromJSON(json: any): Trip {
    return TripFromJSONTyped(json, false);
}

export function TripFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trip {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'grouping': json['grouping'] == null ? undefined : json['grouping'],
        'sortDatetime': json['sort_datetime'] == null ? undefined : (new Date(json['sort_datetime'])),
        'hotels': json['hotels'] == null ? undefined : TripHotelsFromJSON(json['hotels']),
        'flights': json['flights'] == null ? undefined : TripFlightsFromJSON(json['flights']),
    };
}

export function TripToJSON(value?: Trip | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'grouping': value['grouping'],
        'sort_datetime': value['sortDatetime'] == null ? undefined : ((value['sortDatetime']).toISOString()),
        'hotels': TripHotelsToJSON(value['hotels']),
        'flights': TripFlightsToJSON(value['flights']),
    };
}

