/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserInput
 */
export interface UserInput {
    /**
     * 
     * @type {string}
     * @memberof UserInput
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInput
     */
    lastName?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserInput
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserInput
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInput
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInput
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInput
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInput
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInput
     */
    country?: string;
}

/**
 * Check if a given object implements the UserInput interface.
 */
export function instanceOfUserInput(value: object): value is UserInput {
    return true;
}

export function UserInputFromJSON(json: any): UserInput {
    return UserInputFromJSONTyped(json, false);
}

export function UserInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInput {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'dateOfBirth': json['date_of_birth'] == null ? undefined : (new Date(json['date_of_birth'])),
        'addressLine1': json['address_line_1'] == null ? undefined : json['address_line_1'],
        'addressLine2': json['address_line_2'] == null ? undefined : json['address_line_2'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'zipCode': json['zip_code'] == null ? undefined : json['zip_code'],
        'country': json['country'] == null ? undefined : json['country'],
    };
}

export function UserInputToJSON(value?: UserInput | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'date_of_birth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth']).toISOString().substring(0,10)),
        'address_line_1': value['addressLine1'],
        'address_line_2': value['addressLine2'],
        'city': value['city'],
        'state': value['state'],
        'zip_code': value['zipCode'],
        'country': value['country'],
    };
}

