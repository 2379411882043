/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FlightSegment } from './FlightSegment';
import {
    FlightSegmentFromJSON,
    FlightSegmentFromJSONTyped,
    FlightSegmentToJSON,
} from './FlightSegment';
import type { FlightBookingDetails } from './FlightBookingDetails';
import {
    FlightBookingDetailsFromJSON,
    FlightBookingDetailsFromJSONTyped,
    FlightBookingDetailsToJSON,
} from './FlightBookingDetails';

/**
 * 
 * @export
 * @interface FlightReservation
 */
export interface FlightReservation {
    /**
     * 
     * @type {string}
     * @memberof FlightReservation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FlightReservation
     */
    emailId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FlightReservation
     */
    cancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FlightReservation
     */
    departureCity?: string;
    /**
     * 
     * @type {string}
     * @memberof FlightReservation
     */
    arrivalCity?: string;
    /**
     * 
     * @type {string}
     * @memberof FlightReservation
     */
    confirmationNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof FlightReservation
     */
    totalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof FlightReservation
     */
    currency?: string;
    /**
     * 
     * @type {number}
     * @memberof FlightReservation
     */
    totalMilesRedeemed?: number;
    /**
     * 
     * @type {string}
     * @memberof FlightReservation
     */
    bookingAgent?: string;
    /**
     * 
     * @type {Date}
     * @memberof FlightReservation
     */
    bookingDate?: Date;
    /**
     * 
     * @type {FlightBookingDetails}
     * @memberof FlightReservation
     */
    bookingDetails?: FlightBookingDetails;
    /**
     * 
     * @type {string}
     * @memberof FlightReservation
     */
    flightBrand?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FlightReservation
     */
    isRoundTrip?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FlightReservation
     */
    grouping?: FlightReservationGroupingEnum;
    /**
     * 
     * @type {Array<FlightSegment>}
     * @memberof FlightReservation
     */
    flightSegments?: Array<FlightSegment>;
    /**
     * 
     * @type {string}
     * @memberof FlightReservation
     */
    tripId?: string;
}


/**
 * @export
 */
export const FlightReservationGroupingEnum = {
    Past: 'past',
    Future: 'future'
} as const;
export type FlightReservationGroupingEnum = typeof FlightReservationGroupingEnum[keyof typeof FlightReservationGroupingEnum];


/**
 * Check if a given object implements the FlightReservation interface.
 */
export function instanceOfFlightReservation(value: object): value is FlightReservation {
    return true;
}

export function FlightReservationFromJSON(json: any): FlightReservation {
    return FlightReservationFromJSONTyped(json, false);
}

export function FlightReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlightReservation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'emailId': json['email_id'] == null ? undefined : json['email_id'],
        'cancelled': json['cancelled'] == null ? undefined : json['cancelled'],
        'departureCity': json['departure_city'] == null ? undefined : json['departure_city'],
        'arrivalCity': json['arrival_city'] == null ? undefined : json['arrival_city'],
        'confirmationNumber': json['confirmation_number'] == null ? undefined : json['confirmation_number'],
        'totalCost': json['total_cost'] == null ? undefined : json['total_cost'],
        'currency': json['currency'] == null ? undefined : json['currency'],
        'totalMilesRedeemed': json['total_miles_redeemed'] == null ? undefined : json['total_miles_redeemed'],
        'bookingAgent': json['booking_agent'] == null ? undefined : json['booking_agent'],
        'bookingDate': json['booking_date'] == null ? undefined : (new Date(json['booking_date'])),
        'bookingDetails': json['booking_details'] == null ? undefined : FlightBookingDetailsFromJSON(json['booking_details']),
        'flightBrand': json['flight_brand'] == null ? undefined : json['flight_brand'],
        'isRoundTrip': json['is_round_trip'] == null ? undefined : json['is_round_trip'],
        'grouping': json['grouping'] == null ? undefined : json['grouping'],
        'flightSegments': json['flight_segments'] == null ? undefined : ((json['flight_segments'] as Array<any>).map(FlightSegmentFromJSON)),
        'tripId': json['trip_id'] == null ? undefined : json['trip_id'],
    };
}

export function FlightReservationToJSON(value?: FlightReservation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email_id': value['emailId'],
        'cancelled': value['cancelled'],
        'departure_city': value['departureCity'],
        'arrival_city': value['arrivalCity'],
        'confirmation_number': value['confirmationNumber'],
        'total_cost': value['totalCost'],
        'currency': value['currency'],
        'total_miles_redeemed': value['totalMilesRedeemed'],
        'booking_agent': value['bookingAgent'],
        'booking_date': value['bookingDate'] == null ? undefined : ((value['bookingDate']).toISOString()),
        'booking_details': FlightBookingDetailsToJSON(value['bookingDetails']),
        'flight_brand': value['flightBrand'],
        'is_round_trip': value['isRoundTrip'],
        'grouping': value['grouping'],
        'flight_segments': value['flightSegments'] == null ? undefined : ((value['flightSegments'] as Array<any>).map(FlightSegmentToJSON)),
        'trip_id': value['tripId'],
    };
}

