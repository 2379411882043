/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MxSyncRequest
 */
export interface MxSyncRequest {
    /**
     * 
     * @type {string}
     * @memberof MxSyncRequest
     */
    mxUserGuid: string;
    /**
     * 
     * @type {string}
     * @memberof MxSyncRequest
     */
    mxMemberGuid: string;
    /**
     * 
     * @type {string}
     * @memberof MxSyncRequest
     */
    institutionCode: string;
}

/**
 * Check if a given object implements the MxSyncRequest interface.
 */
export function instanceOfMxSyncRequest(value: object): value is MxSyncRequest {
    if (!('mxUserGuid' in value) || value['mxUserGuid'] === undefined) return false;
    if (!('mxMemberGuid' in value) || value['mxMemberGuid'] === undefined) return false;
    if (!('institutionCode' in value) || value['institutionCode'] === undefined) return false;
    return true;
}

export function MxSyncRequestFromJSON(json: any): MxSyncRequest {
    return MxSyncRequestFromJSONTyped(json, false);
}

export function MxSyncRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MxSyncRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'mxUserGuid': json['mx_user_guid'],
        'mxMemberGuid': json['mx_member_guid'],
        'institutionCode': json['institution_code'],
    };
}

export function MxSyncRequestToJSON(value?: MxSyncRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mx_user_guid': value['mxUserGuid'],
        'mx_member_guid': value['mxMemberGuid'],
        'institution_code': value['institutionCode'],
    };
}

