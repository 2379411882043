/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAttributes
 */
export interface UserAttributes {
    /**
     * 
     * @type {string}
     * @memberof UserAttributes
     */
    fbclid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAttributes
     */
    sendSiteAccessEmail?: boolean;
}

/**
 * Check if a given object implements the UserAttributes interface.
 */
export function instanceOfUserAttributes(value: object): value is UserAttributes {
    return true;
}

export function UserAttributesFromJSON(json: any): UserAttributes {
    return UserAttributesFromJSONTyped(json, false);
}

export function UserAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAttributes {
    if (json == null) {
        return json;
    }
    return {
        
        'fbclid': json['fbclid'] == null ? undefined : json['fbclid'],
        'sendSiteAccessEmail': json['send_site_access_email'] == null ? undefined : json['send_site_access_email'],
    };
}

export function UserAttributesToJSON(value?: UserAttributes | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fbclid': value['fbclid'],
        'send_site_access_email': value['sendSiteAccessEmail'],
    };
}

