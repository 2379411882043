/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberStayRequest
 */
export interface MemberStayRequest {
    /**
     * The property ids to get stays for.
     * @type {Array<number>}
     * @memberof MemberStayRequest
     */
    vervotechPropertyIds: Array<number>;
}

/**
 * Check if a given object implements the MemberStayRequest interface.
 */
export function instanceOfMemberStayRequest(value: object): value is MemberStayRequest {
    if (!('vervotechPropertyIds' in value) || value['vervotechPropertyIds'] === undefined) return false;
    return true;
}

export function MemberStayRequestFromJSON(json: any): MemberStayRequest {
    return MemberStayRequestFromJSONTyped(json, false);
}

export function MemberStayRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberStayRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'vervotechPropertyIds': json['vervotech_property_ids'],
    };
}

export function MemberStayRequestToJSON(value?: MemberStayRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vervotech_property_ids': value['vervotechPropertyIds'],
    };
}

