/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RatePastStayWelcomeAction
 */
export interface RatePastStayWelcomeAction {
    /**
     * 
     * @type {number}
     * @memberof RatePastStayWelcomeAction
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RatePastStayWelcomeAction
     */
    bookingId: number;
    /**
     * 
     * @type {number}
     * @memberof RatePastStayWelcomeAction
     */
    vervotechPropertyId?: number;
    /**
     * 
     * @type {number}
     * @memberof RatePastStayWelcomeAction
     */
    providerPropertyId?: number;
    /**
     * 
     * @type {string}
     * @memberof RatePastStayWelcomeAction
     */
    propertyName?: string;
    /**
     * 
     * @type {string}
     * @memberof RatePastStayWelcomeAction
     */
    propertyAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof RatePastStayWelcomeAction
     */
    chainName?: string;
    /**
     * 
     * @type {string}
     * @memberof RatePastStayWelcomeAction
     */
    brandName?: string;
    /**
     * URL of hero image
     * @type {string}
     * @memberof RatePastStayWelcomeAction
     */
    heroImage?: string;
    /**
     * Average guest rating
     * @type {number}
     * @memberof RatePastStayWelcomeAction
     */
    averageGuestRating?: number;
}

/**
 * Check if a given object implements the RatePastStayWelcomeAction interface.
 */
export function instanceOfRatePastStayWelcomeAction(value: object): value is RatePastStayWelcomeAction {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('bookingId' in value) || value['bookingId'] === undefined) return false;
    return true;
}

export function RatePastStayWelcomeActionFromJSON(json: any): RatePastStayWelcomeAction {
    return RatePastStayWelcomeActionFromJSONTyped(json, false);
}

export function RatePastStayWelcomeActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatePastStayWelcomeAction {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'bookingId': json['booking_id'],
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'providerPropertyId': json['provider_property_id'] == null ? undefined : json['provider_property_id'],
        'propertyName': json['property_name'] == null ? undefined : json['property_name'],
        'propertyAddress': json['property_address'] == null ? undefined : json['property_address'],
        'chainName': json['chain_name'] == null ? undefined : json['chain_name'],
        'brandName': json['brand_name'] == null ? undefined : json['brand_name'],
        'heroImage': json['hero_image'] == null ? undefined : json['hero_image'],
        'averageGuestRating': json['average_guest_rating'] == null ? undefined : json['average_guest_rating'],
    };
}

export function RatePastStayWelcomeActionToJSON(value?: RatePastStayWelcomeAction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'booking_id': value['bookingId'],
        'vervotech_property_id': value['vervotechPropertyId'],
        'provider_property_id': value['providerPropertyId'],
        'property_name': value['propertyName'],
        'property_address': value['propertyAddress'],
        'chain_name': value['chainName'],
        'brand_name': value['brandName'],
        'hero_image': value['heroImage'],
        'average_guest_rating': value['averageGuestRating'],
    };
}

