/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CardProvider = {
    Visa: 'visa',
    Mastercard: 'mastercard',
    Amex: 'amex',
    Discover: 'discover'
} as const;
export type CardProvider = typeof CardProvider[keyof typeof CardProvider];


export function instanceOfCardProvider(value: any): boolean {
    for (const key in CardProvider) {
        if (Object.prototype.hasOwnProperty.call(CardProvider, key)) {
            if ((CardProvider as Record<string, CardProvider>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CardProviderFromJSON(json: any): CardProvider {
    return CardProviderFromJSONTyped(json, false);
}

export function CardProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardProvider {
    return json as CardProvider;
}

export function CardProviderToJSON(value?: CardProvider | null): any {
    return value as any;
}
