/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RecommendationReasons } from './RecommendationReasons';
import {
    RecommendationReasonsFromJSON,
    RecommendationReasonsFromJSONTyped,
    RecommendationReasonsToJSON,
} from './RecommendationReasons';

/**
 * A summary of a recommendation
 * @export
 * @interface RecommendationSummary
 */
export interface RecommendationSummary {
    /**
     * 
     * @type {boolean}
     * @memberof RecommendationSummary
     */
    recommended?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    pastStays: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    pastStaysScore: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    reviewCount?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    reviewCountScore?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    proximity?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    proximityScore: number;
    /**
     * 
     * @type {boolean}
     * @memberof RecommendationSummary
     */
    hasLoyalty: boolean;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    hasLoyaltyScore: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    ratingScore: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    guestRating?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    guestRatingScore: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    multiStayUsers?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    multiStayUsersScore?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    priceScore?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    pointsScore?: number;
    /**
     * 
     * @type {number}
     * @memberof RecommendationSummary
     */
    totalScore: number;
    /**
     * 
     * @type {Array<RecommendationReasons>}
     * @memberof RecommendationSummary
     */
    recommendationReasons?: Array<RecommendationReasons>;
}

/**
 * Check if a given object implements the RecommendationSummary interface.
 */
export function instanceOfRecommendationSummary(value: object): value is RecommendationSummary {
    if (!('pastStays' in value) || value['pastStays'] === undefined) return false;
    if (!('pastStaysScore' in value) || value['pastStaysScore'] === undefined) return false;
    if (!('proximityScore' in value) || value['proximityScore'] === undefined) return false;
    if (!('hasLoyalty' in value) || value['hasLoyalty'] === undefined) return false;
    if (!('hasLoyaltyScore' in value) || value['hasLoyaltyScore'] === undefined) return false;
    if (!('ratingScore' in value) || value['ratingScore'] === undefined) return false;
    if (!('guestRatingScore' in value) || value['guestRatingScore'] === undefined) return false;
    if (!('totalScore' in value) || value['totalScore'] === undefined) return false;
    return true;
}

export function RecommendationSummaryFromJSON(json: any): RecommendationSummary {
    return RecommendationSummaryFromJSONTyped(json, false);
}

export function RecommendationSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecommendationSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'recommended': json['recommended'] == null ? undefined : json['recommended'],
        'pastStays': json['past_stays'],
        'pastStaysScore': json['past_stays_score'],
        'reviewCount': json['review_count'] == null ? undefined : json['review_count'],
        'reviewCountScore': json['review_count_score'] == null ? undefined : json['review_count_score'],
        'proximity': json['proximity'] == null ? undefined : json['proximity'],
        'proximityScore': json['proximity_score'],
        'hasLoyalty': json['has_loyalty'],
        'hasLoyaltyScore': json['has_loyalty_score'],
        'rating': json['rating'] == null ? undefined : json['rating'],
        'ratingScore': json['rating_score'],
        'guestRating': json['guest_rating'] == null ? undefined : json['guest_rating'],
        'guestRatingScore': json['guest_rating_score'],
        'multiStayUsers': json['multi_stay_users'] == null ? undefined : json['multi_stay_users'],
        'multiStayUsersScore': json['multi_stay_users_score'] == null ? undefined : json['multi_stay_users_score'],
        'price': json['price'] == null ? undefined : json['price'],
        'priceScore': json['price_score'] == null ? undefined : json['price_score'],
        'points': json['points'] == null ? undefined : json['points'],
        'pointsScore': json['points_score'] == null ? undefined : json['points_score'],
        'totalScore': json['total_score'],
        'recommendationReasons': json['recommendation_reasons'] == null ? undefined : ((json['recommendation_reasons'] as Array<any>).map(RecommendationReasonsFromJSON)),
    };
}

export function RecommendationSummaryToJSON(value?: RecommendationSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'recommended': value['recommended'],
        'past_stays': value['pastStays'],
        'past_stays_score': value['pastStaysScore'],
        'review_count': value['reviewCount'],
        'review_count_score': value['reviewCountScore'],
        'proximity': value['proximity'],
        'proximity_score': value['proximityScore'],
        'has_loyalty': value['hasLoyalty'],
        'has_loyalty_score': value['hasLoyaltyScore'],
        'rating': value['rating'],
        'rating_score': value['ratingScore'],
        'guest_rating': value['guestRating'],
        'guest_rating_score': value['guestRatingScore'],
        'multi_stay_users': value['multiStayUsers'],
        'multi_stay_users_score': value['multiStayUsersScore'],
        'price': value['price'],
        'price_score': value['priceScore'],
        'points': value['points'],
        'points_score': value['pointsScore'],
        'total_score': value['totalScore'],
        'recommendation_reasons': value['recommendationReasons'] == null ? undefined : ((value['recommendationReasons'] as Array<any>).map(RecommendationReasonsToJSON)),
    };
}

