/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Flight } from './Flight';
import {
    FlightFromJSON,
    FlightFromJSONTyped,
    FlightToJSON,
} from './Flight';

/**
 * 
 * @export
 * @interface TripFlights
 */
export interface TripFlights {
    /**
     * 
     * @type {Array<Flight>}
     * @memberof TripFlights
     */
    items?: Array<Flight>;
}

/**
 * Check if a given object implements the TripFlights interface.
 */
export function instanceOfTripFlights(value: object): value is TripFlights {
    return true;
}

export function TripFlightsFromJSON(json: any): TripFlights {
    return TripFlightsFromJSONTyped(json, false);
}

export function TripFlightsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripFlights {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(FlightFromJSON)),
    };
}

export function TripFlightsToJSON(value?: TripFlights | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(FlightToJSON)),
    };
}

