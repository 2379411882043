/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransferPartner } from './TransferPartner';
import {
    TransferPartnerFromJSON,
    TransferPartnerFromJSONTyped,
    TransferPartnerToJSON,
} from './TransferPartner';
import type { LoyaltyProgramEnum } from './LoyaltyProgramEnum';
import {
    LoyaltyProgramEnumFromJSON,
    LoyaltyProgramEnumFromJSONTyped,
    LoyaltyProgramEnumToJSON,
} from './LoyaltyProgramEnum';

/**
 * 
 * @export
 * @interface LoyaltyProgram
 */
export interface LoyaltyProgram {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyProgram
     */
    brandName: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyProgram
     */
    brandType: string;
    /**
     * 
     * @type {LoyaltyProgramEnum}
     * @memberof LoyaltyProgram
     */
    loyaltyProgram: LoyaltyProgramEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoyaltyProgram
     */
    items: Array<string>;
    /**
     * All transfer partners available in the search results
     * @type {Array<TransferPartner>}
     * @memberof LoyaltyProgram
     */
    availableTransferPartners?: Array<TransferPartner>;
}

/**
 * Check if a given object implements the LoyaltyProgram interface.
 */
export function instanceOfLoyaltyProgram(value: object): value is LoyaltyProgram {
    if (!('brandName' in value) || value['brandName'] === undefined) return false;
    if (!('brandType' in value) || value['brandType'] === undefined) return false;
    if (!('loyaltyProgram' in value) || value['loyaltyProgram'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function LoyaltyProgramFromJSON(json: any): LoyaltyProgram {
    return LoyaltyProgramFromJSONTyped(json, false);
}

export function LoyaltyProgramFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyProgram {
    if (json == null) {
        return json;
    }
    return {
        
        'brandName': json['brand_name'],
        'brandType': json['brand_type'],
        'loyaltyProgram': LoyaltyProgramEnumFromJSON(json['loyalty_program']),
        'items': json['items'],
        'availableTransferPartners': json['available_transfer_partners'] == null ? undefined : ((json['available_transfer_partners'] as Array<any>).map(TransferPartnerFromJSON)),
    };
}

export function LoyaltyProgramToJSON(value?: LoyaltyProgram | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'brand_name': value['brandName'],
        'brand_type': value['brandType'],
        'loyalty_program': LoyaltyProgramEnumToJSON(value['loyaltyProgram']),
        'items': value['items'],
        'available_transfer_partners': value['availableTransferPartners'] == null ? undefined : ((value['availableTransferPartners'] as Array<any>).map(TransferPartnerToJSON)),
    };
}

