/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailSyncTokenExpiredWelcomeAction
 */
export interface EmailSyncTokenExpiredWelcomeAction {
    /**
     * 
     * @type {number}
     * @memberof EmailSyncTokenExpiredWelcomeAction
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof EmailSyncTokenExpiredWelcomeAction
     */
    accountId: string;
}

/**
 * Check if a given object implements the EmailSyncTokenExpiredWelcomeAction interface.
 */
export function instanceOfEmailSyncTokenExpiredWelcomeAction(value: object): value is EmailSyncTokenExpiredWelcomeAction {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    return true;
}

export function EmailSyncTokenExpiredWelcomeActionFromJSON(json: any): EmailSyncTokenExpiredWelcomeAction {
    return EmailSyncTokenExpiredWelcomeActionFromJSONTyped(json, false);
}

export function EmailSyncTokenExpiredWelcomeActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailSyncTokenExpiredWelcomeAction {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'accountId': json['account_id'],
    };
}

export function EmailSyncTokenExpiredWelcomeActionToJSON(value?: EmailSyncTokenExpiredWelcomeAction | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'account_id': value['accountId'],
    };
}

