/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A category rating
 * @export
 * @interface RatingForCategory
 */
export interface RatingForCategory {
    /**
     * Name of the category
     * @type {string}
     * @memberof RatingForCategory
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof RatingForCategory
     */
    rating?: number;
}

/**
 * Check if a given object implements the RatingForCategory interface.
 */
export function instanceOfRatingForCategory(value: object): value is RatingForCategory {
    return true;
}

export function RatingForCategoryFromJSON(json: any): RatingForCategory {
    return RatingForCategoryFromJSONTyped(json, false);
}

export function RatingForCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatingForCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'category': json['category'] == null ? undefined : json['category'],
        'rating': json['rating'] == null ? undefined : json['rating'],
    };
}

export function RatingForCategoryToJSON(value?: RatingForCategory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'category': value['category'],
        'rating': value['rating'],
    };
}

