/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RatePastStayRequest
 */
export interface RatePastStayRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RatePastStayRequest
     */
    stayHereAgain: boolean;
}

/**
 * Check if a given object implements the RatePastStayRequest interface.
 */
export function instanceOfRatePastStayRequest(value: object): value is RatePastStayRequest {
    if (!('stayHereAgain' in value) || value['stayHereAgain'] === undefined) return false;
    return true;
}

export function RatePastStayRequestFromJSON(json: any): RatePastStayRequest {
    return RatePastStayRequestFromJSONTyped(json, false);
}

export function RatePastStayRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatePastStayRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'stayHereAgain': json['stay_here_again'],
    };
}

export function RatePastStayRequestToJSON(value?: RatePastStayRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stay_here_again': value['stayHereAgain'],
    };
}

