/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FlightEarnings
 */
export interface FlightEarnings {
    /**
     * Premier Qualifying Points -- United
     * @type {number}
     * @memberof FlightEarnings
     */
    pQP?: number;
    /**
     * Premier Qualifying Flights -- United
     * @type {number}
     * @memberof FlightEarnings
     */
    pQF?: number;
    /**
     * Medallion Qualifying Dollars -- Delta
     * @type {number}
     * @memberof FlightEarnings
     */
    mDQ?: number;
    /**
     * Elite Qualifying Miles -- Alaska
     * @type {number}
     * @memberof FlightEarnings
     */
    eQM?: number;
    /**
     * Number of loyalty points accrued -- American
     * @type {number}
     * @memberof FlightEarnings
     */
    loyaltyPoints?: number;
    /**
     * Number of award miles accrued -- United, American, Delta, Alaska
     * @type {number}
     * @memberof FlightEarnings
     */
    miles?: number;
}

/**
 * Check if a given object implements the FlightEarnings interface.
 */
export function instanceOfFlightEarnings(value: object): value is FlightEarnings {
    return true;
}

export function FlightEarningsFromJSON(json: any): FlightEarnings {
    return FlightEarningsFromJSONTyped(json, false);
}

export function FlightEarningsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlightEarnings {
    if (json == null) {
        return json;
    }
    return {
        
        'pQP': json['PQP'] == null ? undefined : json['PQP'],
        'pQF': json['PQF'] == null ? undefined : json['PQF'],
        'mDQ': json['MDQ'] == null ? undefined : json['MDQ'],
        'eQM': json['EQM'] == null ? undefined : json['EQM'],
        'loyaltyPoints': json['loyalty_points'] == null ? undefined : json['loyalty_points'],
        'miles': json['miles'] == null ? undefined : json['miles'],
    };
}

export function FlightEarningsToJSON(value?: FlightEarnings | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'PQP': value['pQP'],
        'PQF': value['pQF'],
        'MDQ': value['mDQ'],
        'EQM': value['eQM'],
        'loyalty_points': value['loyaltyPoints'],
        'miles': value['miles'],
    };
}

