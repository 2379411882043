/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailType } from './EmailType';
import {
    EmailTypeFromJSON,
    EmailTypeFromJSONTyped,
    EmailTypeToJSON,
} from './EmailType';

/**
 * 
 * @export
 * @interface CanSendEmailRequest
 */
export interface CanSendEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof CanSendEmailRequest
     */
    userId: string;
    /**
     * 
     * @type {EmailType}
     * @memberof CanSendEmailRequest
     */
    emailType: EmailType;
    /**
     * 
     * @type {number}
     * @memberof CanSendEmailRequest
     */
    bookingId?: number;
    /**
     * 
     * @type {number}
     * @memberof CanSendEmailRequest
     */
    windowInDays?: number;
}

/**
 * Check if a given object implements the CanSendEmailRequest interface.
 */
export function instanceOfCanSendEmailRequest(value: object): value is CanSendEmailRequest {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('emailType' in value) || value['emailType'] === undefined) return false;
    return true;
}

export function CanSendEmailRequestFromJSON(json: any): CanSendEmailRequest {
    return CanSendEmailRequestFromJSONTyped(json, false);
}

export function CanSendEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CanSendEmailRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
        'emailType': EmailTypeFromJSON(json['email_type']),
        'bookingId': json['booking_id'] == null ? undefined : json['booking_id'],
        'windowInDays': json['window_in_days'] == null ? undefined : json['window_in_days'],
    };
}

export function CanSendEmailRequestToJSON(value?: CanSendEmailRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_id': value['userId'],
        'email_type': EmailTypeToJSON(value['emailType']),
        'booking_id': value['bookingId'],
        'window_in_days': value['windowInDays'],
    };
}

