/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserStatus } from './UserStatus';
import {
    UserStatusFromJSON,
    UserStatusFromJSONTyped,
    UserStatusToJSON,
} from './UserStatus';
import type { UserAttributes } from './UserAttributes';
import {
    UserAttributesFromJSON,
    UserAttributesFromJSONTyped,
    UserAttributesToJSON,
} from './UserAttributes';

/**
 * 
 * @export
 * @interface UserPutRequest
 */
export interface UserPutRequest {
    /**
     * 
     * @type {UserStatus}
     * @memberof UserPutRequest
     */
    status: UserStatus;
    /**
     * 
     * @type {UserAttributes}
     * @memberof UserPutRequest
     */
    attributes?: UserAttributes;
}

/**
 * Check if a given object implements the UserPutRequest interface.
 */
export function instanceOfUserPutRequest(value: object): value is UserPutRequest {
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function UserPutRequestFromJSON(json: any): UserPutRequest {
    return UserPutRequestFromJSONTyped(json, false);
}

export function UserPutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPutRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'status': UserStatusFromJSON(json['status']),
        'attributes': json['attributes'] == null ? undefined : UserAttributesFromJSON(json['attributes']),
    };
}

export function UserPutRequestToJSON(value?: UserPutRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': UserStatusToJSON(value['status']),
        'attributes': UserAttributesToJSON(value['attributes']),
    };
}

