/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoyaltyAccountLoyaltyRequest
 */
export interface LoyaltyAccountLoyaltyRequest {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyAccountLoyaltyRequest
     */
    fieldName: string;
    /**
     * 
     * @type {object}
     * @memberof LoyaltyAccountLoyaltyRequest
     */
    requiredUserInput: object;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyAccountLoyaltyRequest
     */
    requestType: LoyaltyAccountLoyaltyRequestRequestTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyAccountLoyaltyRequest
     */
    loyaltyRequestInProgress: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoyaltyAccountLoyaltyRequest
     */
    loyaltyRequestAvailable: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyAccountLoyaltyRequest
     */
    loyaltyRequestError?: string;
}


/**
 * @export
 */
export const LoyaltyAccountLoyaltyRequestRequestTypeEnum = {
    Call: 'call',
    Email: 'email'
} as const;
export type LoyaltyAccountLoyaltyRequestRequestTypeEnum = typeof LoyaltyAccountLoyaltyRequestRequestTypeEnum[keyof typeof LoyaltyAccountLoyaltyRequestRequestTypeEnum];


/**
 * Check if a given object implements the LoyaltyAccountLoyaltyRequest interface.
 */
export function instanceOfLoyaltyAccountLoyaltyRequest(value: object): value is LoyaltyAccountLoyaltyRequest {
    if (!('fieldName' in value) || value['fieldName'] === undefined) return false;
    if (!('requiredUserInput' in value) || value['requiredUserInput'] === undefined) return false;
    if (!('requestType' in value) || value['requestType'] === undefined) return false;
    if (!('loyaltyRequestInProgress' in value) || value['loyaltyRequestInProgress'] === undefined) return false;
    if (!('loyaltyRequestAvailable' in value) || value['loyaltyRequestAvailable'] === undefined) return false;
    return true;
}

export function LoyaltyAccountLoyaltyRequestFromJSON(json: any): LoyaltyAccountLoyaltyRequest {
    return LoyaltyAccountLoyaltyRequestFromJSONTyped(json, false);
}

export function LoyaltyAccountLoyaltyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyAccountLoyaltyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'fieldName': json['field_name'],
        'requiredUserInput': json['required_user_input'],
        'requestType': json['request_type'],
        'loyaltyRequestInProgress': json['loyalty_request_in_progress'],
        'loyaltyRequestAvailable': json['loyalty_request_available'],
        'loyaltyRequestError': json['loyalty_request_error'] == null ? undefined : json['loyalty_request_error'],
    };
}

export function LoyaltyAccountLoyaltyRequestToJSON(value?: LoyaltyAccountLoyaltyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'field_name': value['fieldName'],
        'required_user_input': value['requiredUserInput'],
        'request_type': value['requestType'],
        'loyalty_request_in_progress': value['loyaltyRequestInProgress'],
        'loyalty_request_available': value['loyaltyRequestAvailable'],
        'loyalty_request_error': value['loyaltyRequestError'],
    };
}

