/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberStaySummary
 */
export interface MemberStaySummary {
    /**
     * The property id of the stay.
     * @type {number}
     * @memberof MemberStaySummary
     */
    vervotechPropertyId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MemberStaySummary
     */
    stayCounts?: Array<number>;
}

/**
 * Check if a given object implements the MemberStaySummary interface.
 */
export function instanceOfMemberStaySummary(value: object): value is MemberStaySummary {
    return true;
}

export function MemberStaySummaryFromJSON(json: any): MemberStaySummary {
    return MemberStaySummaryFromJSONTyped(json, false);
}

export function MemberStaySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberStaySummary {
    if (json == null) {
        return json;
    }
    return {
        
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'stayCounts': json['stay_counts'] == null ? undefined : json['stay_counts'],
    };
}

export function MemberStaySummaryToJSON(value?: MemberStaySummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vervotech_property_id': value['vervotechPropertyId'],
        'stay_counts': value['stayCounts'],
    };
}

