/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuccessResponse
 */
export interface SuccessResponse {
    /**
     * 
     * @type {string}
     * @memberof SuccessResponse
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponse
     */
    taskId?: string;
}

/**
 * Check if a given object implements the SuccessResponse interface.
 */
export function instanceOfSuccessResponse(value: object): value is SuccessResponse {
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function SuccessResponseFromJSON(json: any): SuccessResponse {
    return SuccessResponseFromJSONTyped(json, false);
}

export function SuccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuccessResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
        'taskId': json['task_id'] == null ? undefined : json['task_id'],
    };
}

export function SuccessResponseToJSON(value?: SuccessResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'task_id': value['taskId'],
    };
}

