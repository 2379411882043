/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Itinerary } from './Itinerary';
import {
    ItineraryFromJSON,
    ItineraryFromJSONTyped,
    ItineraryToJSON,
} from './Itinerary';
import type { SyncStatus } from './SyncStatus';
import {
    SyncStatusFromJSON,
    SyncStatusFromJSONTyped,
    SyncStatusToJSON,
} from './SyncStatus';

/**
 * 
 * @export
 * @interface Itineraries
 */
export interface Itineraries {
    /**
     * 
     * @type {Array<Itinerary>}
     * @memberof Itineraries
     */
    items?: Array<Itinerary>;
    /**
     * 
     * @type {SyncStatus}
     * @memberof Itineraries
     */
    syncStatus?: SyncStatus;
}

/**
 * Check if a given object implements the Itineraries interface.
 */
export function instanceOfItineraries(value: object): value is Itineraries {
    return true;
}

export function ItinerariesFromJSON(json: any): Itineraries {
    return ItinerariesFromJSONTyped(json, false);
}

export function ItinerariesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Itineraries {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(ItineraryFromJSON)),
        'syncStatus': json['sync_status'] == null ? undefined : SyncStatusFromJSON(json['sync_status']),
    };
}

export function ItinerariesToJSON(value?: Itineraries | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(ItineraryToJSON)),
        'sync_status': SyncStatusToJSON(value['syncStatus']),
    };
}

