/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserInput } from './UserInput';
import {
    UserInputFromJSON,
    UserInputFromJSONTyped,
    UserInputToJSON,
} from './UserInput';

/**
 * 
 * @export
 * @interface LoyaltyRequest
 */
export interface LoyaltyRequest {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRequest
     */
    accountId: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyRequest
     */
    fieldName: string;
    /**
     * 
     * @type {UserInput}
     * @memberof LoyaltyRequest
     */
    requiredUserInput: UserInput;
}

/**
 * Check if a given object implements the LoyaltyRequest interface.
 */
export function instanceOfLoyaltyRequest(value: object): value is LoyaltyRequest {
    if (!('accountId' in value) || value['accountId'] === undefined) return false;
    if (!('fieldName' in value) || value['fieldName'] === undefined) return false;
    if (!('requiredUserInput' in value) || value['requiredUserInput'] === undefined) return false;
    return true;
}

export function LoyaltyRequestFromJSON(json: any): LoyaltyRequest {
    return LoyaltyRequestFromJSONTyped(json, false);
}

export function LoyaltyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'accountId': json['account_id'],
        'fieldName': json['field_name'],
        'requiredUserInput': UserInputFromJSON(json['required_user_input']),
    };
}

export function LoyaltyRequestToJSON(value?: LoyaltyRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'account_id': value['accountId'],
        'field_name': value['fieldName'],
        'required_user_input': UserInputToJSON(value['requiredUserInput']),
    };
}

