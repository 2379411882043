/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrencyAmount } from './CurrencyAmount';
import {
    CurrencyAmountFromJSON,
    CurrencyAmountFromJSONTyped,
    CurrencyAmountToJSON,
} from './CurrencyAmount';

/**
 * 
 * @export
 * @interface UserCreditsDetail
 */
export interface UserCreditsDetail {
    /**
     * 
     * @type {number}
     * @memberof UserCreditsDetail
     */
    referralsCount: number;
    /**
     * The number of referrals available; the number of referrals is capped to a certain number.
     * @type {number}
     * @memberof UserCreditsDetail
     */
    referralsAvailable: number;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof UserCreditsDetail
     */
    referralEarnings: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof UserCreditsDetail
     */
    pendingReferralEarnings: CurrencyAmount;
    /**
     * The number of Gondola bookings.
     * @type {number}
     * @memberof UserCreditsDetail
     */
    gondolaBookingsCount: number;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof UserCreditsDetail
     */
    gondolaBookingsEarnings: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof UserCreditsDetail
     */
    pendingGondolaBookingsEarnings: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof UserCreditsDetail
     */
    totalGondolaCash: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof UserCreditsDetail
     */
    potentialEarnings: CurrencyAmount;
}

/**
 * Check if a given object implements the UserCreditsDetail interface.
 */
export function instanceOfUserCreditsDetail(value: object): value is UserCreditsDetail {
    if (!('referralsCount' in value) || value['referralsCount'] === undefined) return false;
    if (!('referralsAvailable' in value) || value['referralsAvailable'] === undefined) return false;
    if (!('referralEarnings' in value) || value['referralEarnings'] === undefined) return false;
    if (!('pendingReferralEarnings' in value) || value['pendingReferralEarnings'] === undefined) return false;
    if (!('gondolaBookingsCount' in value) || value['gondolaBookingsCount'] === undefined) return false;
    if (!('gondolaBookingsEarnings' in value) || value['gondolaBookingsEarnings'] === undefined) return false;
    if (!('pendingGondolaBookingsEarnings' in value) || value['pendingGondolaBookingsEarnings'] === undefined) return false;
    if (!('totalGondolaCash' in value) || value['totalGondolaCash'] === undefined) return false;
    if (!('potentialEarnings' in value) || value['potentialEarnings'] === undefined) return false;
    return true;
}

export function UserCreditsDetailFromJSON(json: any): UserCreditsDetail {
    return UserCreditsDetailFromJSONTyped(json, false);
}

export function UserCreditsDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreditsDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'referralsCount': json['referrals_count'],
        'referralsAvailable': json['referrals_available'],
        'referralEarnings': CurrencyAmountFromJSON(json['referral_earnings']),
        'pendingReferralEarnings': CurrencyAmountFromJSON(json['pending_referral_earnings']),
        'gondolaBookingsCount': json['gondola_bookings_count'],
        'gondolaBookingsEarnings': CurrencyAmountFromJSON(json['gondola_bookings_earnings']),
        'pendingGondolaBookingsEarnings': CurrencyAmountFromJSON(json['pending_gondola_bookings_earnings']),
        'totalGondolaCash': CurrencyAmountFromJSON(json['total_gondola_cash']),
        'potentialEarnings': CurrencyAmountFromJSON(json['potential_earnings']),
    };
}

export function UserCreditsDetailToJSON(value?: UserCreditsDetail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'referrals_count': value['referralsCount'],
        'referrals_available': value['referralsAvailable'],
        'referral_earnings': CurrencyAmountToJSON(value['referralEarnings']),
        'pending_referral_earnings': CurrencyAmountToJSON(value['pendingReferralEarnings']),
        'gondola_bookings_count': value['gondolaBookingsCount'],
        'gondola_bookings_earnings': CurrencyAmountToJSON(value['gondolaBookingsEarnings']),
        'pending_gondola_bookings_earnings': CurrencyAmountToJSON(value['pendingGondolaBookingsEarnings']),
        'total_gondola_cash': CurrencyAmountToJSON(value['totalGondolaCash']),
        'potential_earnings': CurrencyAmountToJSON(value['potentialEarnings']),
    };
}

