/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MxRewardUpdateRequest
 */
export interface MxRewardUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MxRewardUpdateRequest
     */
    creditCardProductName: string;
    /**
     * 
     * @type {number}
     * @memberof MxRewardUpdateRequest
     */
    balance: number;
}

/**
 * Check if a given object implements the MxRewardUpdateRequest interface.
 */
export function instanceOfMxRewardUpdateRequest(value: object): value is MxRewardUpdateRequest {
    if (!('creditCardProductName' in value) || value['creditCardProductName'] === undefined) return false;
    if (!('balance' in value) || value['balance'] === undefined) return false;
    return true;
}

export function MxRewardUpdateRequestFromJSON(json: any): MxRewardUpdateRequest {
    return MxRewardUpdateRequestFromJSONTyped(json, false);
}

export function MxRewardUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MxRewardUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'creditCardProductName': json['credit_card_product_name'],
        'balance': json['balance'],
    };
}

export function MxRewardUpdateRequestToJSON(value?: MxRewardUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'credit_card_product_name': value['creditCardProductName'],
        'balance': value['balance'],
    };
}

