/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelSummary } from './HotelSummary';
import {
    HotelSummaryFromJSON,
    HotelSummaryFromJSONTyped,
    HotelSummaryToJSON,
} from './HotelSummary';
import type { Hotel } from './Hotel';
import {
    HotelFromJSON,
    HotelFromJSONTyped,
    HotelToJSON,
} from './Hotel';

/**
 * 
 * @export
 * @interface HotelReservation
 */
export interface HotelReservation {
    /**
     * 
     * @type {HotelSummary}
     * @memberof HotelReservation
     */
    summary?: HotelSummary;
    /**
     * 
     * @type {Hotel}
     * @memberof HotelReservation
     */
    reservation?: Hotel;
}

/**
 * Check if a given object implements the HotelReservation interface.
 */
export function instanceOfHotelReservation(value: object): value is HotelReservation {
    return true;
}

export function HotelReservationFromJSON(json: any): HotelReservation {
    return HotelReservationFromJSONTyped(json, false);
}

export function HotelReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelReservation {
    if (json == null) {
        return json;
    }
    return {
        
        'summary': json['summary'] == null ? undefined : HotelSummaryFromJSON(json['summary']),
        'reservation': json['reservation'] == null ? undefined : HotelFromJSON(json['reservation']),
    };
}

export function HotelReservationToJSON(value?: HotelReservation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'summary': HotelSummaryToJSON(value['summary']),
        'reservation': HotelToJSON(value['reservation']),
    };
}

