/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MemberStaySummary } from './MemberStaySummary';
import {
    MemberStaySummaryFromJSON,
    MemberStaySummaryFromJSONTyped,
    MemberStaySummaryToJSON,
} from './MemberStaySummary';

/**
 * 
 * @export
 * @interface MemberStayResponse
 */
export interface MemberStayResponse {
    /**
     * 
     * @type {Array<MemberStaySummary>}
     * @memberof MemberStayResponse
     */
    stays?: Array<MemberStaySummary>;
}

/**
 * Check if a given object implements the MemberStayResponse interface.
 */
export function instanceOfMemberStayResponse(value: object): value is MemberStayResponse {
    return true;
}

export function MemberStayResponseFromJSON(json: any): MemberStayResponse {
    return MemberStayResponseFromJSONTyped(json, false);
}

export function MemberStayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberStayResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'stays': json['stays'] == null ? undefined : ((json['stays'] as Array<any>).map(MemberStaySummaryFromJSON)),
    };
}

export function MemberStayResponseToJSON(value?: MemberStayResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stays': value['stays'] == null ? undefined : ((value['stays'] as Array<any>).map(MemberStaySummaryToJSON)),
    };
}

