/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CallTranscriptRequest,
  HTTPUnauthorizedError,
  HTTPValidationError,
  SuccessResponse,
} from '../models/index';
import {
    CallTranscriptRequestFromJSON,
    CallTranscriptRequestToJSON,
    HTTPUnauthorizedErrorFromJSON,
    HTTPUnauthorizedErrorToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
} from '../models/index';

export interface VoiceCallTranscriptPostRequest {
    callTranscriptRequest: CallTranscriptRequest;
}

/**
 * 
 */
export class VoiceApi extends runtime.BaseAPI {

    /**
     * Call Transcript
     */
    async voiceCallTranscriptPostRaw(requestParameters: VoiceCallTranscriptPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponse>> {
        if (requestParameters['callTranscriptRequest'] == null) {
            throw new runtime.RequiredError(
                'callTranscriptRequest',
                'Required parameter "callTranscriptRequest" was null or undefined when calling voiceCallTranscriptPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/voice/call_transcript`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CallTranscriptRequestToJSON(requestParameters['callTranscriptRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
    }

    /**
     * Call Transcript
     */
    async voiceCallTranscriptPost(requestParameters: VoiceCallTranscriptPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponse> {
        const response = await this.voiceCallTranscriptPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
