/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FlightReservation } from './FlightReservation';
import {
    FlightReservationFromJSON,
    FlightReservationFromJSONTyped,
    FlightReservationToJSON,
} from './FlightReservation';

/**
 * 
 * @export
 * @interface ItineraryFlights
 */
export interface ItineraryFlights {
    /**
     * 
     * @type {Array<FlightReservation>}
     * @memberof ItineraryFlights
     */
    items?: Array<FlightReservation>;
}

/**
 * Check if a given object implements the ItineraryFlights interface.
 */
export function instanceOfItineraryFlights(value: object): value is ItineraryFlights {
    return true;
}

export function ItineraryFlightsFromJSON(json: any): ItineraryFlights {
    return ItineraryFlightsFromJSONTyped(json, false);
}

export function ItineraryFlightsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItineraryFlights {
    if (json == null) {
        return json;
    }
    return {
        
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(FlightReservationFromJSON)),
    };
}

export function ItineraryFlightsToJSON(value?: ItineraryFlights | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(FlightReservationToJSON)),
    };
}

