/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyncAccountResponse
 */
export interface SyncAccountResponse {
    /**
     * The number of email accounts synced
     * @type {number}
     * @memberof SyncAccountResponse
     */
    syncedAccounts?: number;
    /**
     * The number of loyalty accounts possilbe to sync
     * @type {number}
     * @memberof SyncAccountResponse
     */
    loyaltyAccounts?: number;
}

/**
 * Check if a given object implements the SyncAccountResponse interface.
 */
export function instanceOfSyncAccountResponse(value: object): value is SyncAccountResponse {
    return true;
}

export function SyncAccountResponseFromJSON(json: any): SyncAccountResponse {
    return SyncAccountResponseFromJSONTyped(json, false);
}

export function SyncAccountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncAccountResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'syncedAccounts': json['synced_accounts'] == null ? undefined : json['synced_accounts'],
        'loyaltyAccounts': json['loyalty_accounts'] == null ? undefined : json['loyalty_accounts'],
    };
}

export function SyncAccountResponseToJSON(value?: SyncAccountResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'synced_accounts': value['syncedAccounts'],
        'loyalty_accounts': value['loyaltyAccounts'],
    };
}

