/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RoomView = {
    Bay: 'Bay',
    Beach: 'Beach',
    Canal: 'Canal',
    City: 'City',
    Courtyard: 'Courtyard',
    Desert: 'Desert',
    Garden: 'Garden',
    Golf: 'Golf',
    Harbor: 'Harbor',
    Hill: 'Hill',
    Lagoon: 'Lagoon',
    Lake: 'Lake',
    Park: 'Park',
    PartialLake: 'Partial lake',
    Marina: 'Marina',
    Mountain: 'Mountain',
    Ocean: 'Ocean',
    PartialOcean: 'Partial ocean',
    Pool: 'Pool',
    Resort: 'Resort',
    River: 'River',
    Sea: 'Sea',
    PartialSea: 'Partial sea',
    Water: 'Water',
    Valley: 'Valley',
    Vineyard: 'Vineyard'
} as const;
export type RoomView = typeof RoomView[keyof typeof RoomView];


export function instanceOfRoomView(value: any): boolean {
    for (const key in RoomView) {
        if (Object.prototype.hasOwnProperty.call(RoomView, key)) {
            if ((RoomView as Record<string, RoomView>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RoomViewFromJSON(json: any): RoomView {
    return RoomViewFromJSONTyped(json, false);
}

export function RoomViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomView {
    return json as RoomView;
}

export function RoomViewToJSON(value?: RoomView | null): any {
    return value as any;
}
