/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPUnauthorizedError,
  HTTPValidationError,
  LoyaltyProgram,
  LoyaltyRedemptionValueResponse,
} from '../models/index';
import {
    HTTPUnauthorizedErrorFromJSON,
    HTTPUnauthorizedErrorToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    LoyaltyProgramFromJSON,
    LoyaltyProgramToJSON,
    LoyaltyRedemptionValueResponseFromJSON,
    LoyaltyRedemptionValueResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class LoyaltyApi extends runtime.BaseAPI {

    /**
     * Get all supported loyalty programs and their associated tiers.
     */
    async loyaltyProgramsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LoyaltyProgram>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/loyalty_programs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoyaltyProgramFromJSON));
    }

    /**
     * Get all supported loyalty programs and their associated tiers.
     */
    async loyaltyProgramsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LoyaltyProgram>> {
        const response = await this.loyaltyProgramsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get loyalty redemption values.
     */
    async loyaltyRedemptionValuesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoyaltyRedemptionValueResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/loyalty_redemption_values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoyaltyRedemptionValueResponseFromJSON(jsonValue));
    }

    /**
     * Get loyalty redemption values.
     */
    async loyaltyRedemptionValuesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoyaltyRedemptionValueResponse> {
        const response = await this.loyaltyRedemptionValuesGetRaw(initOverrides);
        return await response.value();
    }

}
