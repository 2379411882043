/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StayTag = {
    GreatPointsDeal: 'Great Points Deal',
    GreatCashDeal: 'Great Cash Deal',
    RecommendedForYou: 'Recommended for You',
    HighlyRated: 'Highly Rated',
    MaximumEarnings: 'Maximum Earnings',
    FrequentStays: 'Frequent Stays',
    ClosestToLocation: 'Closest to Location',
    RecentlyRenovated: 'Recently Renovated'
} as const;
export type StayTag = typeof StayTag[keyof typeof StayTag];


export function instanceOfStayTag(value: any): boolean {
    for (const key in StayTag) {
        if (Object.prototype.hasOwnProperty.call(StayTag, key)) {
            if ((StayTag as Record<string, StayTag>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function StayTagFromJSON(json: any): StayTag {
    return StayTagFromJSONTyped(json, false);
}

export function StayTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): StayTag {
    return json as StayTag;
}

export function StayTagToJSON(value?: StayTag | null): any {
    return value as any;
}
