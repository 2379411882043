/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Curated content for a hotel
 * @export
 * @interface ExternalReview
 */
export interface ExternalReview {
    /**
     * 
     * @type {number}
     * @memberof ExternalReview
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ExternalReview
     */
    vervotechPropertyId: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalReview
     */
    mappedPropertyName: string;
    /**
     * The source of the curated content, e.g. Conde Nast
     * @type {string}
     * @memberof ExternalReview
     */
    sourceName: string;
    /**
     * The name of the article
     * @type {string}
     * @memberof ExternalReview
     */
    articleName: string;
    /**
     * The link to the source of the curated content
     * @type {string}
     * @memberof ExternalReview
     */
    sourceLink: string;
    /**
     * The content of the curated article
     * @type {string}
     * @memberof ExternalReview
     */
    content: string;
    /**
     * An excerpt of the content
     * @type {string}
     * @memberof ExternalReview
     */
    excerpt?: string;
    /**
     * The date the article was reviewed
     * @type {Date}
     * @memberof ExternalReview
     */
    dateReviewed?: Date;
}

/**
 * Check if a given object implements the ExternalReview interface.
 */
export function instanceOfExternalReview(value: object): value is ExternalReview {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('vervotechPropertyId' in value) || value['vervotechPropertyId'] === undefined) return false;
    if (!('mappedPropertyName' in value) || value['mappedPropertyName'] === undefined) return false;
    if (!('sourceName' in value) || value['sourceName'] === undefined) return false;
    if (!('articleName' in value) || value['articleName'] === undefined) return false;
    if (!('sourceLink' in value) || value['sourceLink'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function ExternalReviewFromJSON(json: any): ExternalReview {
    return ExternalReviewFromJSONTyped(json, false);
}

export function ExternalReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalReview {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'vervotechPropertyId': json['vervotech_property_id'],
        'mappedPropertyName': json['mapped_property_name'],
        'sourceName': json['source_name'],
        'articleName': json['article_name'],
        'sourceLink': json['source_link'],
        'content': json['content'],
        'excerpt': json['excerpt'] == null ? undefined : json['excerpt'],
        'dateReviewed': json['date_reviewed'] == null ? undefined : (new Date(json['date_reviewed'])),
    };
}

export function ExternalReviewToJSON(value?: ExternalReview | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'vervotech_property_id': value['vervotechPropertyId'],
        'mapped_property_name': value['mappedPropertyName'],
        'source_name': value['sourceName'],
        'article_name': value['articleName'],
        'source_link': value['sourceLink'],
        'content': value['content'],
        'excerpt': value['excerpt'],
        'date_reviewed': value['dateReviewed'] == null ? undefined : ((value['dateReviewed']).toISOString().substring(0,10)),
    };
}

