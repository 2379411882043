/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentMethodPutRequest
 */
export interface PaymentMethodPutRequest {
    /**
     * Indicates if this is the default payment method.
     * @type {boolean}
     * @memberof PaymentMethodPutRequest
     */
    _default?: boolean;
    /**
     * Tokenized card CVC.  Exists in VGS volatile storage
     * @type {string}
     * @memberof PaymentMethodPutRequest
     */
    cardCvcTokenized?: string;
}

/**
 * Check if a given object implements the PaymentMethodPutRequest interface.
 */
export function instanceOfPaymentMethodPutRequest(value: object): value is PaymentMethodPutRequest {
    return true;
}

export function PaymentMethodPutRequestFromJSON(json: any): PaymentMethodPutRequest {
    return PaymentMethodPutRequestFromJSONTyped(json, false);
}

export function PaymentMethodPutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodPutRequest {
    if (json == null) {
        return json;
    }
    return {
        
        '_default': json['default'] == null ? undefined : json['default'],
        'cardCvcTokenized': json['card_cvc_tokenized'] == null ? undefined : json['card_cvc_tokenized'],
    };
}

export function PaymentMethodPutRequestToJSON(value?: PaymentMethodPutRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'default': value['_default'],
        'card_cvc_tokenized': value['cardCvcTokenized'],
    };
}

