/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TravelProfile
 */
export interface TravelProfile {
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    gender?: TravelProfileGenderEnum;
    /**
     * 
     * @type {Date}
     * @memberof TravelProfile
     */
    birthday?: Date;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    redressNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    knownTravelerNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    legalFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    legalLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof TravelProfile
     */
    passportNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof TravelProfile
     */
    passportExpirationDate?: Date;
}


/**
 * @export
 */
export const TravelProfileGenderEnum = {
    Man: 'man',
    Woman: 'woman',
    NonBinary: 'non-binary',
    Unspecified: 'unspecified'
} as const;
export type TravelProfileGenderEnum = typeof TravelProfileGenderEnum[keyof typeof TravelProfileGenderEnum];


/**
 * Check if a given object implements the TravelProfile interface.
 */
export function instanceOfTravelProfile(value: object): value is TravelProfile {
    return true;
}

export function TravelProfileFromJSON(json: any): TravelProfile {
    return TravelProfileFromJSONTyped(json, false);
}

export function TravelProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'firstName': json['first_name'] == null ? undefined : json['first_name'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'gender': json['gender'] == null ? undefined : json['gender'],
        'birthday': json['birthday'] == null ? undefined : (new Date(json['birthday'])),
        'redressNumber': json['redress_number'] == null ? undefined : json['redress_number'],
        'knownTravelerNumber': json['known_traveler_number'] == null ? undefined : json['known_traveler_number'],
        'phoneNumber': json['phone_number'] == null ? undefined : json['phone_number'],
        'email': json['email'] == null ? undefined : json['email'],
        'legalFirstName': json['legal_first_name'] == null ? undefined : json['legal_first_name'],
        'legalLastName': json['legal_last_name'] == null ? undefined : json['legal_last_name'],
        'passportNumber': json['passport_number'] == null ? undefined : json['passport_number'],
        'passportExpirationDate': json['passport_expiration_date'] == null ? undefined : (new Date(json['passport_expiration_date'])),
    };
}

export function TravelProfileToJSON(value?: TravelProfile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user_id': value['userId'],
        'first_name': value['firstName'],
        'last_name': value['lastName'],
        'gender': value['gender'],
        'birthday': value['birthday'] == null ? undefined : ((value['birthday']).toISOString().substring(0,10)),
        'redress_number': value['redressNumber'],
        'known_traveler_number': value['knownTravelerNumber'],
        'phone_number': value['phoneNumber'],
        'email': value['email'],
        'legal_first_name': value['legalFirstName'],
        'legal_last_name': value['legalLastName'],
        'passport_number': value['passportNumber'],
        'passport_expiration_date': value['passportExpirationDate'] == null ? undefined : ((value['passportExpirationDate']).toISOString().substring(0,10)),
    };
}

