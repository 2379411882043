/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RatePastStayWelcomeAction } from './RatePastStayWelcomeAction';
import {
    RatePastStayWelcomeActionFromJSON,
    RatePastStayWelcomeActionFromJSONTyped,
    RatePastStayWelcomeActionToJSON,
} from './RatePastStayWelcomeAction';

/**
 * 
 * @export
 * @interface RatePastStayWelcomeActions
 */
export interface RatePastStayWelcomeActions {
    /**
     * 
     * @type {Array<RatePastStayWelcomeAction>}
     * @memberof RatePastStayWelcomeActions
     */
    items: Array<RatePastStayWelcomeAction>;
}

/**
 * Check if a given object implements the RatePastStayWelcomeActions interface.
 */
export function instanceOfRatePastStayWelcomeActions(value: object): value is RatePastStayWelcomeActions {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function RatePastStayWelcomeActionsFromJSON(json: any): RatePastStayWelcomeActions {
    return RatePastStayWelcomeActionsFromJSONTyped(json, false);
}

export function RatePastStayWelcomeActionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatePastStayWelcomeActions {
    if (json == null) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(RatePastStayWelcomeActionFromJSON)),
    };
}

export function RatePastStayWelcomeActionsToJSON(value?: RatePastStayWelcomeActions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'items': ((value['items'] as Array<any>).map(RatePastStayWelcomeActionToJSON)),
    };
}

