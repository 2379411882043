/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailType } from './EmailType';
import {
    EmailTypeFromJSON,
    EmailTypeFromJSONTyped,
    EmailTypeToJSON,
} from './EmailType';

/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    resendId?: string;
    /**
     * 
     * @type {EmailType}
     * @memberof Email
     */
    emailType: EmailType;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    bookingId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Email
     */
    sentAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    errorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the Email interface.
 */
export function instanceOfEmail(value: object): value is Email {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('emailType' in value) || value['emailType'] === undefined) return false;
    return true;
}

export function EmailFromJSON(json: any): Email {
    return EmailFromJSONTyped(json, false);
}

export function EmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): Email {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
        'resendId': json['resend_id'] == null ? undefined : json['resend_id'],
        'emailType': EmailTypeFromJSON(json['email_type']),
        'bookingId': json['booking_id'] == null ? undefined : json['booking_id'],
        'sentAt': json['sent_at'] == null ? undefined : (new Date(json['sent_at'])),
        'errorCode': json['error_code'] == null ? undefined : json['error_code'],
        'errorMessage': json['error_message'] == null ? undefined : json['error_message'],
    };
}

export function EmailToJSON(value?: Email | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_id': value['userId'],
        'resend_id': value['resendId'],
        'email_type': EmailTypeToJSON(value['emailType']),
        'booking_id': value['bookingId'],
        'sent_at': value['sentAt'] == null ? undefined : ((value['sentAt']).toISOString()),
        'error_code': value['errorCode'],
        'error_message': value['errorMessage'],
    };
}

