/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrencyAmount } from './CurrencyAmount';
import {
    CurrencyAmountFromJSON,
    CurrencyAmountFromJSONTyped,
    CurrencyAmountToJSON,
} from './CurrencyAmount';

/**
 * 
 * @export
 * @interface UserBookingCredits
 */
export interface UserBookingCredits {
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof UserBookingCredits
     */
    referralCredits: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof UserBookingCredits
     */
    promotionalCredits: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof UserBookingCredits
     */
    cashbackCredits: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof UserBookingCredits
     */
    ambassadorCredits: CurrencyAmount;
}

/**
 * Check if a given object implements the UserBookingCredits interface.
 */
export function instanceOfUserBookingCredits(value: object): value is UserBookingCredits {
    if (!('referralCredits' in value) || value['referralCredits'] === undefined) return false;
    if (!('promotionalCredits' in value) || value['promotionalCredits'] === undefined) return false;
    if (!('cashbackCredits' in value) || value['cashbackCredits'] === undefined) return false;
    if (!('ambassadorCredits' in value) || value['ambassadorCredits'] === undefined) return false;
    return true;
}

export function UserBookingCreditsFromJSON(json: any): UserBookingCredits {
    return UserBookingCreditsFromJSONTyped(json, false);
}

export function UserBookingCreditsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserBookingCredits {
    if (json == null) {
        return json;
    }
    return {
        
        'referralCredits': CurrencyAmountFromJSON(json['referral_credits']),
        'promotionalCredits': CurrencyAmountFromJSON(json['promotional_credits']),
        'cashbackCredits': CurrencyAmountFromJSON(json['cashback_credits']),
        'ambassadorCredits': CurrencyAmountFromJSON(json['ambassador_credits']),
    };
}

export function UserBookingCreditsToJSON(value?: UserBookingCredits | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'referral_credits': CurrencyAmountToJSON(value['referralCredits']),
        'promotional_credits': CurrencyAmountToJSON(value['promotionalCredits']),
        'cashback_credits': CurrencyAmountToJSON(value['cashbackCredits']),
        'ambassador_credits': CurrencyAmountToJSON(value['ambassadorCredits']),
    };
}

