/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LoyaltyProgramEnum } from './LoyaltyProgramEnum';
import {
    LoyaltyProgramEnumFromJSON,
    LoyaltyProgramEnumFromJSONTyped,
    LoyaltyProgramEnumToJSON,
} from './LoyaltyProgramEnum';

/**
 * 
 * @export
 * @interface CreateUpdateLoyaltyAccountRequest
 */
export interface CreateUpdateLoyaltyAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateLoyaltyAccountRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateLoyaltyAccountRequest
     */
    brandName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateLoyaltyAccountRequest
     */
    brandType: string;
    /**
     * 
     * @type {LoyaltyProgramEnum}
     * @memberof CreateUpdateLoyaltyAccountRequest
     */
    loyaltyProgram?: LoyaltyProgramEnum;
    /**
     * the user's unique Loyalty ID associated with the loyalty program.  Choose the unmasked ID if possible.'
     * @type {string}
     * @memberof CreateUpdateLoyaltyAccountRequest
     */
    loyaltyId?: string;
    /**
     * the user's masked loyalty id'
     * @type {string}
     * @memberof CreateUpdateLoyaltyAccountRequest
     */
    redactedLoyaltyId?: string;
    /**
     * the user's current loyalty balance
     * @type {number}
     * @memberof CreateUpdateLoyaltyAccountRequest
     */
    loyaltyPoints?: number;
    /**
     * the user's current loyalty status
     * @type {string}
     * @memberof CreateUpdateLoyaltyAccountRequest
     */
    loyaltyStatus?: string;
    /**
     * the user's name associated with the loyalty program.  None if not found.
     * @type {string}
     * @memberof CreateUpdateLoyaltyAccountRequest
     */
    loyaltyUserName?: string;
}

/**
 * Check if a given object implements the CreateUpdateLoyaltyAccountRequest interface.
 */
export function instanceOfCreateUpdateLoyaltyAccountRequest(value: object): value is CreateUpdateLoyaltyAccountRequest {
    if (!('brandName' in value) || value['brandName'] === undefined) return false;
    if (!('brandType' in value) || value['brandType'] === undefined) return false;
    return true;
}

export function CreateUpdateLoyaltyAccountRequestFromJSON(json: any): CreateUpdateLoyaltyAccountRequest {
    return CreateUpdateLoyaltyAccountRequestFromJSONTyped(json, false);
}

export function CreateUpdateLoyaltyAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateLoyaltyAccountRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'brandName': json['brand_name'],
        'brandType': json['brand_type'],
        'loyaltyProgram': json['loyalty_program'] == null ? undefined : LoyaltyProgramEnumFromJSON(json['loyalty_program']),
        'loyaltyId': json['loyalty_id'] == null ? undefined : json['loyalty_id'],
        'redactedLoyaltyId': json['redacted_loyalty_id'] == null ? undefined : json['redacted_loyalty_id'],
        'loyaltyPoints': json['loyalty_points'] == null ? undefined : json['loyalty_points'],
        'loyaltyStatus': json['loyalty_status'] == null ? undefined : json['loyalty_status'],
        'loyaltyUserName': json['loyalty_user_name'] == null ? undefined : json['loyalty_user_name'],
    };
}

export function CreateUpdateLoyaltyAccountRequestToJSON(value?: CreateUpdateLoyaltyAccountRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'brand_name': value['brandName'],
        'brand_type': value['brandType'],
        'loyalty_program': LoyaltyProgramEnumToJSON(value['loyaltyProgram']),
        'loyalty_id': value['loyaltyId'],
        'redacted_loyalty_id': value['redactedLoyaltyId'],
        'loyalty_points': value['loyaltyPoints'],
        'loyalty_status': value['loyaltyStatus'],
        'loyalty_user_name': value['loyaltyUserName'],
    };
}

