/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CardProvider } from './CardProvider';
import {
    CardProviderFromJSON,
    CardProviderFromJSONTyped,
    CardProviderToJSON,
} from './CardProvider';

/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * Unique identifier for the payment method.
     * @type {string}
     * @memberof PaymentMethod
     */
    id?: string;
    /**
     * The ID of the user this payment method belongs to.
     * @type {string}
     * @memberof PaymentMethod
     */
    userId?: string;
    /**
     * Tokenized card number for secure storage.
     * @type {string}
     * @memberof PaymentMethod
     */
    cardNumberTokenized: string;
    /**
     * Tokenized card CVC for secure storage.
     * @type {string}
     * @memberof PaymentMethod
     */
    cardCvcTokenized: string;
    /**
     * Expiration month of the card.
     * @type {string}
     * @memberof PaymentMethod
     */
    expirationMonth: string;
    /**
     * Two-digit expiration year of the card.
     * @type {string}
     * @memberof PaymentMethod
     */
    expirationYear: string;
    /**
     * first name of the cardholder.
     * @type {string}
     * @memberof PaymentMethod
     */
    cardholderFirstName: string;
    /**
     * last name of the cardholder.
     * @type {string}
     * @memberof PaymentMethod
     */
    cardholderLastName: string;
    /**
     * First line of the billing address.
     * @type {string}
     * @memberof PaymentMethod
     */
    billingAddressLine1: string;
    /**
     * Second line of the billing address (optional).
     * @type {string}
     * @memberof PaymentMethod
     */
    billingAddressLine2?: string;
    /**
     * City of the billing address.
     * @type {string}
     * @memberof PaymentMethod
     */
    billingAddressCity: string;
    /**
     * State of the billing address.
     * @type {string}
     * @memberof PaymentMethod
     */
    billingAddressState: string;
    /**
     * ZIP code of the billing address.
     * @type {string}
     * @memberof PaymentMethod
     */
    billingAddressZip: string;
    /**
     * Country of the billing address in two-letter ISO 3166-1 alpha-2 format.
     * @type {string}
     * @memberof PaymentMethod
     */
    billingAddressCountry: string;
    /**
     * 
     * @type {CardProvider}
     * @memberof PaymentMethod
     */
    cardProvider?: CardProvider;
    /**
     * Indicates if this is the default payment method.
     * @type {boolean}
     * @memberof PaymentMethod
     */
    _default: boolean;
    /**
     * Timestamp of when the payment method was created.
     * @type {Date}
     * @memberof PaymentMethod
     */
    createdAt?: Date;
    /**
     * Timestamp of when the payment method was last updated.
     * @type {Date}
     * @memberof PaymentMethod
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the PaymentMethod interface.
 */
export function instanceOfPaymentMethod(value: object): value is PaymentMethod {
    if (!('cardNumberTokenized' in value) || value['cardNumberTokenized'] === undefined) return false;
    if (!('cardCvcTokenized' in value) || value['cardCvcTokenized'] === undefined) return false;
    if (!('expirationMonth' in value) || value['expirationMonth'] === undefined) return false;
    if (!('expirationYear' in value) || value['expirationYear'] === undefined) return false;
    if (!('cardholderFirstName' in value) || value['cardholderFirstName'] === undefined) return false;
    if (!('cardholderLastName' in value) || value['cardholderLastName'] === undefined) return false;
    if (!('billingAddressLine1' in value) || value['billingAddressLine1'] === undefined) return false;
    if (!('billingAddressCity' in value) || value['billingAddressCity'] === undefined) return false;
    if (!('billingAddressState' in value) || value['billingAddressState'] === undefined) return false;
    if (!('billingAddressZip' in value) || value['billingAddressZip'] === undefined) return false;
    if (!('billingAddressCountry' in value) || value['billingAddressCountry'] === undefined) return false;
    if (!('_default' in value) || value['_default'] === undefined) return false;
    return true;
}

export function PaymentMethodFromJSON(json: any): PaymentMethod {
    return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'userId': json['user_id'] == null ? undefined : json['user_id'],
        'cardNumberTokenized': json['card_number_tokenized'],
        'cardCvcTokenized': json['card_cvc_tokenized'],
        'expirationMonth': json['expiration_month'],
        'expirationYear': json['expiration_year'],
        'cardholderFirstName': json['cardholder_first_name'],
        'cardholderLastName': json['cardholder_last_name'],
        'billingAddressLine1': json['billing_address_line_1'],
        'billingAddressLine2': json['billing_address_line_2'] == null ? undefined : json['billing_address_line_2'],
        'billingAddressCity': json['billing_address_city'],
        'billingAddressState': json['billing_address_state'],
        'billingAddressZip': json['billing_address_zip'],
        'billingAddressCountry': json['billing_address_country'],
        'cardProvider': json['card_provider'] == null ? undefined : CardProviderFromJSON(json['card_provider']),
        '_default': json['default'],
        'createdAt': json['created_at'] == null ? undefined : (new Date(json['created_at'])),
        'updatedAt': json['updated_at'] == null ? undefined : (new Date(json['updated_at'])),
    };
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'user_id': value['userId'],
        'card_number_tokenized': value['cardNumberTokenized'],
        'card_cvc_tokenized': value['cardCvcTokenized'],
        'expiration_month': value['expirationMonth'],
        'expiration_year': value['expirationYear'],
        'cardholder_first_name': value['cardholderFirstName'],
        'cardholder_last_name': value['cardholderLastName'],
        'billing_address_line_1': value['billingAddressLine1'],
        'billing_address_line_2': value['billingAddressLine2'],
        'billing_address_city': value['billingAddressCity'],
        'billing_address_state': value['billingAddressState'],
        'billing_address_zip': value['billingAddressZip'],
        'billing_address_country': value['billingAddressCountry'],
        'card_provider': CardProviderToJSON(value['cardProvider']),
        'default': value['_default'],
        'created_at': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updated_at': value['updatedAt'] == null ? undefined : ((value['updatedAt']).toISOString()),
    };
}

