/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AirportHistory } from './AirportHistory';
import {
    AirportHistoryFromJSON,
    AirportHistoryFromJSONTyped,
    AirportHistoryToJSON,
} from './AirportHistory';
import type { SyncStatus } from './SyncStatus';
import {
    SyncStatusFromJSON,
    SyncStatusFromJSONTyped,
    SyncStatusToJSON,
} from './SyncStatus';
import type { HotelHistory } from './HotelHistory';
import {
    HotelHistoryFromJSON,
    HotelHistoryFromJSONTyped,
    HotelHistoryToJSON,
} from './HotelHistory';

/**
 * 
 * @export
 * @interface TravelHistoryResponse
 */
export interface TravelHistoryResponse {
    /**
     * 
     * @type {Array<HotelHistory>}
     * @memberof TravelHistoryResponse
     */
    hotels?: Array<HotelHistory>;
    /**
     * 
     * @type {Array<AirportHistory>}
     * @memberof TravelHistoryResponse
     */
    airports?: Array<AirportHistory>;
    /**
     * 
     * @type {SyncStatus}
     * @memberof TravelHistoryResponse
     */
    syncStatus?: SyncStatus;
}

/**
 * Check if a given object implements the TravelHistoryResponse interface.
 */
export function instanceOfTravelHistoryResponse(value: object): value is TravelHistoryResponse {
    return true;
}

export function TravelHistoryResponseFromJSON(json: any): TravelHistoryResponse {
    return TravelHistoryResponseFromJSONTyped(json, false);
}

export function TravelHistoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelHistoryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'hotels': json['hotels'] == null ? undefined : ((json['hotels'] as Array<any>).map(HotelHistoryFromJSON)),
        'airports': json['airports'] == null ? undefined : ((json['airports'] as Array<any>).map(AirportHistoryFromJSON)),
        'syncStatus': json['sync_status'] == null ? undefined : SyncStatusFromJSON(json['sync_status']),
    };
}

export function TravelHistoryResponseToJSON(value?: TravelHistoryResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hotels': value['hotels'] == null ? undefined : ((value['hotels'] as Array<any>).map(HotelHistoryToJSON)),
        'airports': value['airports'] == null ? undefined : ((value['airports'] as Array<any>).map(AirportHistoryToJSON)),
        'sync_status': SyncStatusToJSON(value['syncStatus']),
    };
}

