/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrencyAmount } from './CurrencyAmount';
import {
    CurrencyAmountFromJSON,
    CurrencyAmountFromJSONTyped,
    CurrencyAmountToJSON,
} from './CurrencyAmount';

/**
 * An amount of points
 * @export
 * @interface PointsAmount
 */
export interface PointsAmount {
    /**
     * 
     * @type {number}
     * @memberof PointsAmount
     */
    value?: number;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof PointsAmount
     */
    cashEquivalent?: CurrencyAmount;
    /**
     * The conversion rate from points to cash, expressed as cents/pt
     * @type {number}
     * @memberof PointsAmount
     */
    conversionRate?: number;
}

/**
 * Check if a given object implements the PointsAmount interface.
 */
export function instanceOfPointsAmount(value: object): value is PointsAmount {
    return true;
}

export function PointsAmountFromJSON(json: any): PointsAmount {
    return PointsAmountFromJSONTyped(json, false);
}

export function PointsAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointsAmount {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'] == null ? undefined : json['value'],
        'cashEquivalent': json['cash_equivalent'] == null ? undefined : CurrencyAmountFromJSON(json['cash_equivalent']),
        'conversionRate': json['conversion_rate'] == null ? undefined : json['conversion_rate'],
    };
}

export function PointsAmountToJSON(value?: PointsAmount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'cash_equivalent': CurrencyAmountToJSON(value['cashEquivalent']),
        'conversion_rate': value['conversionRate'],
    };
}

