/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FlightBookingDetails } from './FlightBookingDetails';
import {
    FlightBookingDetailsFromJSON,
    FlightBookingDetailsFromJSONTyped,
    FlightBookingDetailsToJSON,
} from './FlightBookingDetails';

/**
 * 
 * @export
 * @interface FlightLocation
 */
export interface FlightLocation {
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FlightLocation
     */
    cancelled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    departureIata?: string;
    /**
     * 
     * @type {Date}
     * @memberof FlightLocation
     */
    departureDatetime?: Date;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    arrivalIata?: string;
    /**
     * 
     * @type {Date}
     * @memberof FlightLocation
     */
    arrivalDatetime?: Date;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    flightNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    flightSupplierBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    confirmationNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof FlightLocation
     */
    totalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    currency?: string;
    /**
     * 
     * @type {number}
     * @memberof FlightLocation
     */
    totalMilesRedeemed?: number;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    ticketClass?: string;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    tripId?: string;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    bookingAgent?: string;
    /**
     * 
     * @type {Date}
     * @memberof FlightLocation
     */
    bookingDate?: Date;
    /**
     * 
     * @type {FlightBookingDetails}
     * @memberof FlightLocation
     */
    bookingDetails?: FlightBookingDetails;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    flightOperatingCarrier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FlightLocation
     */
    isRoundTrip?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FlightLocation
     */
    grouping?: FlightLocationGroupingEnum;
    /**
     * True if this is a one way trip or the outbound leg of a round trip.
     * @type {boolean}
     * @memberof FlightLocation
     */
    outboundLeg?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FlightLocation
     */
    departureAirportLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof FlightLocation
     */
    departureAirportLongitude?: number;
    /**
     * 
     * @type {number}
     * @memberof FlightLocation
     */
    arrivalAirportLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof FlightLocation
     */
    arrivalAirportLongitude?: number;
}


/**
 * @export
 */
export const FlightLocationGroupingEnum = {
    Past: 'past',
    Future: 'future'
} as const;
export type FlightLocationGroupingEnum = typeof FlightLocationGroupingEnum[keyof typeof FlightLocationGroupingEnum];


/**
 * Check if a given object implements the FlightLocation interface.
 */
export function instanceOfFlightLocation(value: object): value is FlightLocation {
    return true;
}

export function FlightLocationFromJSON(json: any): FlightLocation {
    return FlightLocationFromJSONTyped(json, false);
}

export function FlightLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlightLocation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'cancelled': json['cancelled'] == null ? undefined : json['cancelled'],
        'departureIata': json['departure_iata'] == null ? undefined : json['departure_iata'],
        'departureDatetime': json['departure_datetime'] == null ? undefined : (new Date(json['departure_datetime'])),
        'arrivalIata': json['arrival_iata'] == null ? undefined : json['arrival_iata'],
        'arrivalDatetime': json['arrival_datetime'] == null ? undefined : (new Date(json['arrival_datetime'])),
        'flightNumber': json['flight_number'] == null ? undefined : json['flight_number'],
        'flightSupplierBrand': json['flight_supplier_brand'] == null ? undefined : json['flight_supplier_brand'],
        'confirmationNumber': json['confirmation_number'] == null ? undefined : json['confirmation_number'],
        'totalCost': json['total_cost'] == null ? undefined : json['total_cost'],
        'currency': json['currency'] == null ? undefined : json['currency'],
        'totalMilesRedeemed': json['total_miles_redeemed'] == null ? undefined : json['total_miles_redeemed'],
        'ticketClass': json['ticket_class'] == null ? undefined : json['ticket_class'],
        'tripId': json['trip_id'] == null ? undefined : json['trip_id'],
        'bookingAgent': json['booking_agent'] == null ? undefined : json['booking_agent'],
        'bookingDate': json['booking_date'] == null ? undefined : (new Date(json['booking_date'])),
        'bookingDetails': json['booking_details'] == null ? undefined : FlightBookingDetailsFromJSON(json['booking_details']),
        'flightOperatingCarrier': json['flight_operating_carrier'] == null ? undefined : json['flight_operating_carrier'],
        'isRoundTrip': json['is_round_trip'] == null ? undefined : json['is_round_trip'],
        'grouping': json['grouping'] == null ? undefined : json['grouping'],
        'outboundLeg': json['outbound_leg'] == null ? undefined : json['outbound_leg'],
        'departureAirportLatitude': json['departure_airport_latitude'] == null ? undefined : json['departure_airport_latitude'],
        'departureAirportLongitude': json['departure_airport_longitude'] == null ? undefined : json['departure_airport_longitude'],
        'arrivalAirportLatitude': json['arrival_airport_latitude'] == null ? undefined : json['arrival_airport_latitude'],
        'arrivalAirportLongitude': json['arrival_airport_longitude'] == null ? undefined : json['arrival_airport_longitude'],
    };
}

export function FlightLocationToJSON(value?: FlightLocation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'cancelled': value['cancelled'],
        'departure_iata': value['departureIata'],
        'departure_datetime': value['departureDatetime'] == null ? undefined : ((value['departureDatetime']).toISOString()),
        'arrival_iata': value['arrivalIata'],
        'arrival_datetime': value['arrivalDatetime'] == null ? undefined : ((value['arrivalDatetime']).toISOString()),
        'flight_number': value['flightNumber'],
        'flight_supplier_brand': value['flightSupplierBrand'],
        'confirmation_number': value['confirmationNumber'],
        'total_cost': value['totalCost'],
        'currency': value['currency'],
        'total_miles_redeemed': value['totalMilesRedeemed'],
        'ticket_class': value['ticketClass'],
        'trip_id': value['tripId'],
        'booking_agent': value['bookingAgent'],
        'booking_date': value['bookingDate'] == null ? undefined : ((value['bookingDate']).toISOString()),
        'booking_details': FlightBookingDetailsToJSON(value['bookingDetails']),
        'flight_operating_carrier': value['flightOperatingCarrier'],
        'is_round_trip': value['isRoundTrip'],
        'grouping': value['grouping'],
        'outbound_leg': value['outboundLeg'],
        'departure_airport_latitude': value['departureAirportLatitude'],
        'departure_airport_longitude': value['departureAirportLongitude'],
        'arrival_airport_latitude': value['arrivalAirportLatitude'],
        'arrival_airport_longitude': value['arrivalAirportLongitude'],
    };
}

