/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FlightSegment
 */
export interface FlightSegment {
    /**
     * 
     * @type {string}
     * @memberof FlightSegment
     */
    departureIata?: string;
    /**
     * 
     * @type {Date}
     * @memberof FlightSegment
     */
    departureDatetime?: Date;
    /**
     * 
     * @type {string}
     * @memberof FlightSegment
     */
    arrivalIata?: string;
    /**
     * 
     * @type {Date}
     * @memberof FlightSegment
     */
    arrivalDatetime?: Date;
    /**
     * 
     * @type {string}
     * @memberof FlightSegment
     */
    flightNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof FlightSegment
     */
    flightOperatingCarrier?: string;
    /**
     * 
     * @type {string}
     * @memberof FlightSegment
     */
    ticketClass?: string;
    /**
     * 
     * @type {number}
     * @memberof FlightSegment
     */
    departureAirportLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof FlightSegment
     */
    departureAirportLongitude?: number;
    /**
     * 
     * @type {number}
     * @memberof FlightSegment
     */
    arrivalAirportLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof FlightSegment
     */
    arrivalAirportLongitude?: number;
}

/**
 * Check if a given object implements the FlightSegment interface.
 */
export function instanceOfFlightSegment(value: object): value is FlightSegment {
    return true;
}

export function FlightSegmentFromJSON(json: any): FlightSegment {
    return FlightSegmentFromJSONTyped(json, false);
}

export function FlightSegmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlightSegment {
    if (json == null) {
        return json;
    }
    return {
        
        'departureIata': json['departure_iata'] == null ? undefined : json['departure_iata'],
        'departureDatetime': json['departure_datetime'] == null ? undefined : (new Date(json['departure_datetime'])),
        'arrivalIata': json['arrival_iata'] == null ? undefined : json['arrival_iata'],
        'arrivalDatetime': json['arrival_datetime'] == null ? undefined : (new Date(json['arrival_datetime'])),
        'flightNumber': json['flight_number'] == null ? undefined : json['flight_number'],
        'flightOperatingCarrier': json['flight_operating_carrier'] == null ? undefined : json['flight_operating_carrier'],
        'ticketClass': json['ticket_class'] == null ? undefined : json['ticket_class'],
        'departureAirportLatitude': json['departure_airport_latitude'] == null ? undefined : json['departure_airport_latitude'],
        'departureAirportLongitude': json['departure_airport_longitude'] == null ? undefined : json['departure_airport_longitude'],
        'arrivalAirportLatitude': json['arrival_airport_latitude'] == null ? undefined : json['arrival_airport_latitude'],
        'arrivalAirportLongitude': json['arrival_airport_longitude'] == null ? undefined : json['arrival_airport_longitude'],
    };
}

export function FlightSegmentToJSON(value?: FlightSegment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'departure_iata': value['departureIata'],
        'departure_datetime': value['departureDatetime'] == null ? undefined : ((value['departureDatetime']).toISOString()),
        'arrival_iata': value['arrivalIata'],
        'arrival_datetime': value['arrivalDatetime'] == null ? undefined : ((value['arrivalDatetime']).toISOString()),
        'flight_number': value['flightNumber'],
        'flight_operating_carrier': value['flightOperatingCarrier'],
        'ticket_class': value['ticketClass'],
        'departure_airport_latitude': value['departureAirportLatitude'],
        'departure_airport_longitude': value['departureAirportLongitude'],
        'arrival_airport_latitude': value['arrivalAirportLatitude'],
        'arrival_airport_longitude': value['arrivalAirportLongitude'],
    };
}

