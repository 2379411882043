/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ItineraryFlights } from './ItineraryFlights';
import {
    ItineraryFlightsFromJSON,
    ItineraryFlightsFromJSONTyped,
    ItineraryFlightsToJSON,
} from './ItineraryFlights';
import type { ItineraryHotels } from './ItineraryHotels';
import {
    ItineraryHotelsFromJSON,
    ItineraryHotelsFromJSONTyped,
    ItineraryHotelsToJSON,
} from './ItineraryHotels';

/**
 * 
 * @export
 * @interface Itinerary
 */
export interface Itinerary {
    /**
     * 
     * @type {string}
     * @memberof Itinerary
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Itinerary
     */
    grouping?: ItineraryGroupingEnum;
    /**
     * 
     * @type {Date}
     * @memberof Itinerary
     */
    sortDatetime?: Date;
    /**
     * 
     * @type {ItineraryHotels}
     * @memberof Itinerary
     */
    hotels?: ItineraryHotels;
    /**
     * 
     * @type {ItineraryFlights}
     * @memberof Itinerary
     */
    flights?: ItineraryFlights;
}


/**
 * @export
 */
export const ItineraryGroupingEnum = {
    Past: 'past',
    Future: 'future'
} as const;
export type ItineraryGroupingEnum = typeof ItineraryGroupingEnum[keyof typeof ItineraryGroupingEnum];


/**
 * Check if a given object implements the Itinerary interface.
 */
export function instanceOfItinerary(value: object): value is Itinerary {
    return true;
}

export function ItineraryFromJSON(json: any): Itinerary {
    return ItineraryFromJSONTyped(json, false);
}

export function ItineraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Itinerary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'grouping': json['grouping'] == null ? undefined : json['grouping'],
        'sortDatetime': json['sort_datetime'] == null ? undefined : (new Date(json['sort_datetime'])),
        'hotels': json['hotels'] == null ? undefined : ItineraryHotelsFromJSON(json['hotels']),
        'flights': json['flights'] == null ? undefined : ItineraryFlightsFromJSON(json['flights']),
    };
}

export function ItineraryToJSON(value?: Itinerary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'grouping': value['grouping'],
        'sort_datetime': value['sortDatetime'] == null ? undefined : ((value['sortDatetime']).toISOString()),
        'hotels': ItineraryHotelsToJSON(value['hotels']),
        'flights': ItineraryFlightsToJSON(value['flights']),
    };
}

