/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoyaltyCallRequest
 */
export interface LoyaltyCallRequest {
    /**
     * 
     * @type {string}
     * @memberof LoyaltyCallRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyCallRequest
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyCallRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyCallRequest
     */
    loyaltyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyCallRequest
     */
    loyaltyProgram?: string;
    /**
     * 
     * @type {string}
     * @memberof LoyaltyCallRequest
     */
    processing?: string;
}

/**
 * Check if a given object implements the LoyaltyCallRequest interface.
 */
export function instanceOfLoyaltyCallRequest(value: object): value is LoyaltyCallRequest {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    return true;
}

export function LoyaltyCallRequestFromJSON(json: any): LoyaltyCallRequest {
    return LoyaltyCallRequestFromJSONTyped(json, false);
}

export function LoyaltyCallRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyCallRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
        'zipCode': json['zip_code'] == null ? undefined : json['zip_code'],
        'lastName': json['last_name'] == null ? undefined : json['last_name'],
        'loyaltyNumber': json['loyalty_number'] == null ? undefined : json['loyalty_number'],
        'loyaltyProgram': json['loyalty_program'] == null ? undefined : json['loyalty_program'],
        'processing': json['processing'] == null ? undefined : json['processing'],
    };
}

export function LoyaltyCallRequestToJSON(value?: LoyaltyCallRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'user_id': value['userId'],
        'zip_code': value['zipCode'],
        'last_name': value['lastName'],
        'loyalty_number': value['loyaltyNumber'],
        'loyalty_program': value['loyaltyProgram'],
        'processing': value['processing'],
    };
}

