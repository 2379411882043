/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPUnauthorizedError,
  HTTPValidationError,
  LoyaltyRequest,
  SuccessResponse,
  SyncAccountResponse,
  TripHistoryRequest,
} from '../models/index';
import {
    HTTPUnauthorizedErrorFromJSON,
    HTTPUnauthorizedErrorToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    LoyaltyRequestFromJSON,
    LoyaltyRequestToJSON,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
    SyncAccountResponseFromJSON,
    SyncAccountResponseToJSON,
    TripHistoryRequestFromJSON,
    TripHistoryRequestToJSON,
} from '../models/index';

export interface EmailLoyaltyRequestPostRequest {
    loyaltyRequest: LoyaltyRequest;
}

export interface EmailSyncPostRequest {
    tripHistoryRequest: TripHistoryRequest;
}

/**
 * 
 */
export class EmailApi extends runtime.BaseAPI {

    /**
     * Loyalty Request
     */
    async emailLoyaltyRequestPostRaw(requestParameters: EmailLoyaltyRequestPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponse>> {
        if (requestParameters['loyaltyRequest'] == null) {
            throw new runtime.RequiredError(
                'loyaltyRequest',
                'Required parameter "loyaltyRequest" was null or undefined when calling emailLoyaltyRequestPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/email/loyalty_request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoyaltyRequestToJSON(requestParameters['loyaltyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
    }

    /**
     * Loyalty Request
     */
    async emailLoyaltyRequestPost(requestParameters: EmailLoyaltyRequestPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponse> {
        const response = await this.emailLoyaltyRequestPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sync
     */
    async emailSyncPostRaw(requestParameters: EmailSyncPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SyncAccountResponse>> {
        if (requestParameters['tripHistoryRequest'] == null) {
            throw new runtime.RequiredError(
                'tripHistoryRequest',
                'Required parameter "tripHistoryRequest" was null or undefined when calling emailSyncPost().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/email/sync`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TripHistoryRequestToJSON(requestParameters['tripHistoryRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SyncAccountResponseFromJSON(jsonValue));
    }

    /**
     * Sync
     */
    async emailSyncPost(requestParameters: EmailSyncPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SyncAccountResponse> {
        const response = await this.emailSyncPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
