/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferralsRequest
 */
export interface ReferralsRequest {
    /**
     * Referral code either of a referring user or from a promotion.
     * @type {string}
     * @memberof ReferralsRequest
     */
    referralCode?: string;
    /**
     * Scheduled event code that referred the user to Gondola.
     * @type {string}
     * @memberof ReferralsRequest
     */
    scheduledEventCode?: string;
}

/**
 * Check if a given object implements the ReferralsRequest interface.
 */
export function instanceOfReferralsRequest(value: object): value is ReferralsRequest {
    return true;
}

export function ReferralsRequestFromJSON(json: any): ReferralsRequest {
    return ReferralsRequestFromJSONTyped(json, false);
}

export function ReferralsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferralsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'referralCode': json['referral_code'] == null ? undefined : json['referral_code'],
        'scheduledEventCode': json['scheduled_event_code'] == null ? undefined : json['scheduled_event_code'],
    };
}

export function ReferralsRequestToJSON(value?: ReferralsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'referral_code': value['referralCode'],
        'scheduled_event_code': value['scheduledEventCode'],
    };
}

