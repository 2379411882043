/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EmailType = {
    BookingConfirmation: 'booking_confirmation',
    DirectBookingProcessing: 'direct_booking_processing',
    IntroCashVsPoints: 'intro_cash_vs_points',
    IntroExplore: 'intro_explore',
    IntroDirectBooking: 'intro_direct_booking',
    IntroForYou: 'intro_for_you',
    IntroGondolaCashBack: 'intro_gondola_cash_back',
    IntroHotelSearch: 'intro_hotel_search',
    IntroLoyaltyDashboard: 'intro_loyalty_dashboard',
    IntroMyTrips: 'intro_my_trips',
    IntroTravelHistory: 'intro_travel_history',
    IntroWelcome: 'intro_welcome',
    PointsUpdate: 'points_update',
    RecommendationsHotel: 'recommendations_hotel',
    SiteAccess: 'site_access',
    Waitlist: 'waitlist'
} as const;
export type EmailType = typeof EmailType[keyof typeof EmailType];


export function instanceOfEmailType(value: any): boolean {
    for (const key in EmailType) {
        if (Object.prototype.hasOwnProperty.call(EmailType, key)) {
            if ((EmailType as Record<string, EmailType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EmailTypeFromJSON(json: any): EmailType {
    return EmailTypeFromJSONTyped(json, false);
}

export function EmailTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailType {
    return json as EmailType;
}

export function EmailTypeToJSON(value?: EmailType | null): any {
    return value as any;
}
