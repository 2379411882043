/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrencyAmount } from './CurrencyAmount';
import {
    CurrencyAmountFromJSON,
    CurrencyAmountFromJSONTyped,
    CurrencyAmountToJSON,
} from './CurrencyAmount';

/**
 * 
 * @export
 * @interface ApplyBookingCreditsRequest
 */
export interface ApplyBookingCreditsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ApplyBookingCreditsRequest
     */
    usedGondolaCredits: boolean;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof ApplyBookingCreditsRequest
     */
    commissionableCost?: CurrencyAmount;
    /**
     * 
     * @type {string}
     * @memberof ApplyBookingCreditsRequest
     */
    scheduledEventCode?: string;
}

/**
 * Check if a given object implements the ApplyBookingCreditsRequest interface.
 */
export function instanceOfApplyBookingCreditsRequest(value: object): value is ApplyBookingCreditsRequest {
    if (!('usedGondolaCredits' in value) || value['usedGondolaCredits'] === undefined) return false;
    return true;
}

export function ApplyBookingCreditsRequestFromJSON(json: any): ApplyBookingCreditsRequest {
    return ApplyBookingCreditsRequestFromJSONTyped(json, false);
}

export function ApplyBookingCreditsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyBookingCreditsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'usedGondolaCredits': json['used_gondola_credits'],
        'commissionableCost': json['commissionable_cost'] == null ? undefined : CurrencyAmountFromJSON(json['commissionable_cost']),
        'scheduledEventCode': json['scheduled_event_code'] == null ? undefined : json['scheduled_event_code'],
    };
}

export function ApplyBookingCreditsRequestToJSON(value?: ApplyBookingCreditsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'used_gondola_credits': value['usedGondolaCredits'],
        'commissionable_cost': CurrencyAmountToJSON(value['commissionableCost']),
        'scheduled_event_code': value['scheduledEventCode'],
    };
}

