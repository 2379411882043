/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TravelProfile } from './TravelProfile';
import {
    TravelProfileFromJSON,
    TravelProfileFromJSONTyped,
    TravelProfileToJSON,
} from './TravelProfile';

/**
 * 
 * @export
 * @interface TravelProfileResponse
 */
export interface TravelProfileResponse {
    /**
     * 
     * @type {Array<TravelProfile>}
     * @memberof TravelProfileResponse
     */
    travelProfiles?: Array<TravelProfile>;
}

/**
 * Check if a given object implements the TravelProfileResponse interface.
 */
export function instanceOfTravelProfileResponse(value: object): value is TravelProfileResponse {
    return true;
}

export function TravelProfileResponseFromJSON(json: any): TravelProfileResponse {
    return TravelProfileResponseFromJSONTyped(json, false);
}

export function TravelProfileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelProfileResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'travelProfiles': json['travel_profiles'] == null ? undefined : ((json['travel_profiles'] as Array<any>).map(TravelProfileFromJSON)),
    };
}

export function TravelProfileResponseToJSON(value?: TravelProfileResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'travel_profiles': value['travelProfiles'] == null ? undefined : ((value['travelProfiles'] as Array<any>).map(TravelProfileToJSON)),
    };
}

