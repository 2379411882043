/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Latitude and longitude
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    longitude: number;
}

/**
 * Check if a given object implements the Coordinates interface.
 */
export function instanceOfCoordinates(value: object): value is Coordinates {
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    return true;
}

export function CoordinatesFromJSON(json: any): Coordinates {
    return CoordinatesFromJSONTyped(json, false);
}

export function CoordinatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coordinates {
    if (json == null) {
        return json;
    }
    return {
        
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}

export function CoordinatesToJSON(value?: Coordinates | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'latitude': value['latitude'],
        'longitude': value['longitude'],
    };
}

