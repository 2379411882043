/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManageGmailRequest
 */
export interface ManageGmailRequest {
    /**
     * 
     * @type {string}
     * @memberof ManageGmailRequest
     */
    action: ManageGmailRequestActionEnum;
    /**
     * 
     * @type {string}
     * @memberof ManageGmailRequest
     */
    accountId?: string;
}


/**
 * @export
 */
export const ManageGmailRequestActionEnum = {
    Validate: 'validate',
    Disconnect: 'disconnect',
    Delete: 'delete',
    DeleteLoyalty: 'delete_loyalty',
    DeleteTravelHistory: 'delete_travel_history'
} as const;
export type ManageGmailRequestActionEnum = typeof ManageGmailRequestActionEnum[keyof typeof ManageGmailRequestActionEnum];


/**
 * Check if a given object implements the ManageGmailRequest interface.
 */
export function instanceOfManageGmailRequest(value: object): value is ManageGmailRequest {
    if (!('action' in value) || value['action'] === undefined) return false;
    return true;
}

export function ManageGmailRequestFromJSON(json: any): ManageGmailRequest {
    return ManageGmailRequestFromJSONTyped(json, false);
}

export function ManageGmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageGmailRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'action': json['action'],
        'accountId': json['account_id'] == null ? undefined : json['account_id'],
    };
}

export function ManageGmailRequestToJSON(value?: ManageGmailRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'action': value['action'],
        'account_id': value['accountId'],
    };
}

