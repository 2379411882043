/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPUnauthorizedError,
  ScheduledEvent,
} from '../models/index';
import {
    HTTPUnauthorizedErrorFromJSON,
    HTTPUnauthorizedErrorToJSON,
    ScheduledEventFromJSON,
    ScheduledEventToJSON,
} from '../models/index';

export interface ScheduledEventsEventCodeGetRequest {
    eventCode: string;
}

/**
 * 
 */
export class ScheduledEventsApi extends runtime.BaseAPI {

    /**
     * Get scheduled event.
     */
    async scheduledEventsEventCodeGetRaw(requestParameters: ScheduledEventsEventCodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduledEvent>> {
        if (requestParameters['eventCode'] == null) {
            throw new runtime.RequiredError(
                'eventCode',
                'Required parameter "eventCode" was null or undefined when calling scheduledEventsEventCodeGet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/scheduled_events/{event_code}`.replace(`{${"event_code"}}`, encodeURIComponent(String(requestParameters['eventCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduledEventFromJSON(jsonValue));
    }

    /**
     * Get scheduled event.
     */
    async scheduledEventsEventCodeGet(requestParameters: ScheduledEventsEventCodeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduledEvent> {
        const response = await this.scheduledEventsEventCodeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
