/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RecommendationReasons = {
    PastStays: 'past_stays',
    ReviewCount: 'review_count',
    Proximity: 'proximity',
    HasLoyalty: 'has_loyalty',
    Rating: 'rating',
    GuestRating: 'guest_rating',
    MultiStayUsers: 'multi_stay_users'
} as const;
export type RecommendationReasons = typeof RecommendationReasons[keyof typeof RecommendationReasons];


export function instanceOfRecommendationReasons(value: any): boolean {
    for (const key in RecommendationReasons) {
        if (Object.prototype.hasOwnProperty.call(RecommendationReasons, key)) {
            if ((RecommendationReasons as Record<string, RecommendationReasons>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RecommendationReasonsFromJSON(json: any): RecommendationReasons {
    return RecommendationReasonsFromJSONTyped(json, false);
}

export function RecommendationReasonsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecommendationReasons {
    return json as RecommendationReasons;
}

export function RecommendationReasonsToJSON(value?: RecommendationReasons | null): any {
    return value as any;
}
